<template>
  <!-- create modal -->
  <div class="modal fade" id="createFromPostModal" tabindex="-1" role="dialog" aria-labelledby="createFromPostModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createFromPostModalLabel">Добавить из шаблона должности</h5>
          <button type="button" class="close" ref="frompostcreateclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <label for="post">Должность</label>
              <select class="form-control shadow-none" id="post" v-model="post">
                <option value="" selected></option>
                <option v-for="p in posts" :key="p.ID" :value="p.ID">{{ p.PROPERTY_VALUES.NAME }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="user">Сотрудники</label><br>
              <div class="row">
                <div class="col-12" v-for="user in postUsers" :key="user">
                  <User class="mb-1 d-inline-block" :user="user"/>
                  <button type="button" class="btn btn-danger align-top ml-2 shadow-none mt-1" aria-label="Close" @click="removeUser(user)">X</button>
                </div>
              </div>
              <button type="button" class="btn btn-info shadow-none mt-1" @click="selectUsers">Выбрать</button>
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="button" class="btn btn-success shadow-none float-right" @click.prevent="create">Добавить</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as STAT } from '../models/stat';
import User from '../components/User';

export default {
  data() {
    return {
      user: {},
      post: '',
      postUsers: [],
      error: '',
    }
  },
  computed: {
    posts() {
      return this.$store.getters.getPosts();
    },
    statTemplates() {
      var statTemplates = [];
      this.posts.forEach(post => {
        if (post.ID == this.post)
          statTemplates = post.statTemplates;
      });
      return statTemplates;
    },
    users() {
      return this.$store.getters.getBX24Users();
    },
  },
  methods: {
    selectUsers() {
      window.BX24.selectUsers(this.updateUsers);
    },
    updateUsers(selectedUsers) {
      this.postUsers = [...new Set(this.postUsers.concat(selectedUsers.map(x => x.id)))];
    },
    removeUser(userId) {
      this.postUsers.splice(this.postUsers.indexOf(userId), 1);
    },
    create() {
      this.error = '';
      if (this.post == '') {
        this.error = 'выберите должность';
      } else if (this.postUsers.length == 0) {
        this.error = 'выберите сотрудника';
      } else {
        var props = [];
        this.statTemplates.forEach(statTemplate => {
          this.postUsers.forEach(user => {
            props.push({
              USER_ID: user,
              POST_ID: this.post,
              STATTEMPLATE_ID: statTemplate.ID,
              NAME: statTemplate.PROPERTY_VALUES.NAME,
              TYPE: statTemplate.PROPERTY_VALUES.TYPE,
              RANGE: statTemplate.PROPERTY_VALUES.RANGE,
            });
          })
        })
        STAT.create(props).then((result) => {
          STAT.get({ID: result}).then(stats => {
            this.$emit('statsCreated', stats);
            this.closeModal();
          })
        });
      }
    },
    closeModal() {
      this.$refs.frompostcreateclose.click();
      this.postUsers = [];
      this.post = '';
    },
  },
  components: {
    User,
  },
}
</script>
