import { createTable, createProperties, deleteTable, getRows, createRow, updateRow, deleteRow } from './api.js';

var model = {
  NAME: 'dealinstruction',
  PROPERTIES: [
    { PROPERTY: 'CATEGORY_ID', NAME: 'Category ID', TYPE: 'N' },
    { PROPERTY: 'STATUS_ID', NAME: 'Status ID', TYPE: 'S' },
    { PROPERTY: 'TEXT', NAME: 'Text', TYPE: 'S' },
  ],
}

function copy(from, to) {
  to.ID = from.ID;
  to.DATE_CREATE = from.DATE_CREATE;
  to.CREATED_BY = from.CREATED_BY;
  to.TIMESTAMP_X = from.TIMESTAMP_X;
  to.MODIFIED_BY = from.MODIFIED_BY;
  to.PROPERTY_VALUES.CATEGORY_ID = from.PROPERTY_VALUES.CATEGORY_ID;
  to.PROPERTY_VALUES.STATUS_ID = from.PROPERTY_VALUES.STATUS_ID;
  to.PROPERTY_VALUES.TEXT = from.PROPERTY_VALUES.TEXT;
}

function prepare() {
  return new Promise((resolve, reject) => {
    createTable(model).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function addProps(props) {
  return new Promise((resolve, reject) => {
    createProperties(model, props).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function clear() {
  return new Promise((resolve, reject) => {
    deleteTable(model).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function get(filter = {}) {
  return new Promise((resolve, reject) => {
    var data = {
      ENTITY: model.NAME,
      SORT: { ID: 'ASC' },
      FILTER: filter,
    };
    getRows(data).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function create(props) {
  return new Promise((resolve, reject) => {
    var data = {
      ENTITY: model.NAME,
      NAME: model.NAME,
      PROPERTIES: props,
    };
    createRow(data).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function update(id, props) {
  return new Promise((resolve, reject) => {
    var data = {
      ENTITY: model.NAME,
      ID: id,
      PROPERTIES: props,
    };
    updateRow(data).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function del(id) {
  return new Promise((resolve, reject) => {
    var data = {
      ENTITY: model.NAME,
      ID: id,
    };
    deleteRow(data).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

export default {
  model,
  copy,
  prepare,
  addProps,
  clear,
  get,
  create,
  update,
  del,
}
