export var fitSize = {
  updated () {
    this.fit();
  },
  mounted() {
    this.fit();
  },
  methods: {
    fit() {
      window.BX24.fitWindow(function() {
        var heightApp = document.getElementById('app').offsetHeight;
        var heightScroll = window.BX24.getScrollSize().scrollHeight;
        if (heightApp < heightScroll)
          if (heightApp < 800) {
            heightApp = 800;
            window.BX24.resizeWindow(document.getElementById('app').offsetWidth, heightApp + 20);
          }
      });
    },
  },
}