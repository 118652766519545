<template>
  <!-- add modal -->
  <div class="modal fade" id="statWidgetModalAdd" tabindex="-1" role="dialog" aria-labelledby="statWidgetModalAddLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="statWidgetModalAddLabel">Добавить виджет</h5>
          <button type="button" class="close" ref="statwidgetaddclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <label for="name">Название</label>
              <input type="text" class="form-control shadow-none" id="name" placeholder="Название виджета" v-model="name">
            </div>
            <div class="form-group">
              <label for="post">Должность</label>
              <select class="form-control shadow-none" id="post" v-model="post" @change="checkedStats = []; stattemplate = ''">
                <option value="" selected></option>
                <option v-for="p in posts" :key="p.ID" :value="p.ID">{{ p.PROPERTY_VALUES.NAME }}</option>
              </select>
            </div>
            <div v-if="post" class="form-group">
              <label for="stattemplate">Статистика</label>
              <select class="form-control shadow-none" id="stattemplate" v-model="stattemplate" @change="checkedStats = []">
                <option value="" selected></option>
                <option v-for="st in filteredStatTemplates" :key="st.ID" :value="st.ID">{{ st.PROPERTY_VALUES.NAME }}</option>
              </select>
            </div>
            <div v-if="stattemplate">
              <label for="stat">Сотрудники</label>
              <div class="form-check mb-1" v-for="stat in filteredStats" :key="stat.ID">
                <input class="form-check-input" type="checkbox" :id="stat.ID" :value="stat.ID" v-model="checkedStats">
                <User class="ml-3" v-if="stat.PROPERTY_VALUES.USER_ID" :user="stat.PROPERTY_VALUES.USER_ID"/>
              </div>
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="button" class="btn btn-success shadow-none float-right" @click="add">Добавить</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '../components/User';

export default {
  data() {
    return {
      name: '',
      post: '',
      stattemplate: '',
      checkedStats: [],
      error: '',
    }
  },
  computed: {
    users() {
      return this.$store.getters.getBX24Users();
    },
    posts() {
      return this.$store.getters.getPosts();
    },
    stats() {
      return this.$store.getters.getStats().stats;
    },
    filteredStatTemplates() {
      var post = this.posts.filter(post => post.ID == this.post);
      if (post.length == 1)
        return post[0].statTemplates;
      else
        return [];
    },
    filteredStats() {
      return this.stats.filter(stat => stat.PROPERTY_VALUES.STATTEMPLATE_ID == this.stattemplate);
    },
  },
  methods: {
    user(userId) {
      var u = null;
      this.users.forEach(user => {
        if (userId == user.ID)
          u = user;
      });
      if (u != null)
        return `${u.LAST_NAME} ${u.NAME}`;
      else
        return 'не назначен';
    },
    add() {
      this.error = '';
      if (this.name.trim().length == 0) {
        this.error = 'введите название';
      } else if (this.post == '') {
        this.error = 'выберите должность';
      } else if (this.stattemplate == '') {
        this.error = 'выберите шаблон статистики';
      } else if (this.checkedStats.length == 0) {
        this.error = 'выберите сотрудников';
      } else {
        var data = {
          NAME: this.name,
          TYPE: this.filteredStats[0].PROPERTY_VALUES.TYPE,
          RANGE: this.filteredStats[0].PROPERTY_VALUES.RANGE,
          POST_ID: this.post,
          STATTEMPLATE_ID: this.stattemplate,
          STATS: this.checkedStats,
        }
        this.$emit('statWidgetAdded', data);
        this.closeModal();
      }
    },
    closeModal() {
      this.$refs.statwidgetaddclose.click();
      this.name = '';
      this.post = '';
      this.stattemplate = '';
      this.checkedStats = [];
      this.error = '';
    },
  },
  components: {
    User,
  },
}
</script>
