<script>
import { Line, mixins } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
  },
  watch: {
    options(options) {
      this.renderChart(this.chartData, options);
    },
  },
  mixins: [mixins.reactiveProp],
  mounted () {
    this.renderChart(this.chartData, this.options);
  },
}
</script>

<style>

</style>
