<template>
  <!-- date info modal -->
  <div class="modal fade" id="dateInfoModal" tabindex="-1" role="dialog" aria-labelledby="dateInfoModalLabel" aria-hidden="true">
    <div class="modal-dialog mw-100 w-75" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="dateInfoModalLabel">{{ autoStatWidgetActive.NAME }} - {{ day }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table m-0">
            <thead class="thead row-header">
              <th scope="col">Сотрудник</th>
              <th scope="col">Значение</th>
              <th scope="col">Список</th>
            </thead>
            <tbody>
              <tr v-for="dataset in dateInfo" :key="dataset.user">
                <td scope="row">{{ dataset.user }}</td>
                <td>{{ dataset.data }}</td>
                <td>
                  <div v-for="value in dataset.values" :key="value.ID" class="row">
                    <div v-if="autoStatWidgetActive.STAT_TYPE==0">
                      {{ created(value) }} - <b>{{ duration(value) }}</b>
                      <button class="btn btn-sm shadow-none" @click="value.ID = -value.ID">
                        <i class="fa fa-file-audio-o" aria-hidden="true"></i>
                      </button>
                      <div v-if="value.ID < 0">
                        <audio controls=""><source :src="file(value)" type="audio/wav"></audio>
                      </div>
                    </div>
                    <div v-if="autoStatWidgetActive.STAT_TYPE==1">
                      {{ value.TITLE }}
                      <a :href="'https://' + BX24Domain.domain + '/crm/company/details/' + value.ID + '/'" target="_blank"><i class="fa fa-external-link" aria-hidden="true"></i></a>
                    </div>
                    <div v-if="autoStatWidgetActive.STAT_TYPE==2">
                      <b>{{ Number.parseInt(value.OPPORTUNITY).toLocaleString("ru-RU") }} р</b> - {{ value.TITLE }}
                      <a :href="'https://' + BX24Domain.domain + '/crm/deal/details/' + value.ID + '/'" target="_blank"><i class="fa fa-external-link" aria-hidden="true"></i></a>
                    </div>
                    <div v-if="autoStatWidgetActive.STAT_TYPE==3">
                      {{ value.SUBJECT }} {{ value.DESCRIPTION }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <form action="#">
            <div class="form-group">
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Закрыть</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fitSize } from '../mixins/fitSize';
var moment = require('moment');

export default {
  props: {
    autoStatWidgetActive: Object,
    dateInfo: Array,
  },
  computed: {
    day() {
      return this.dateInfo.length > 0 ? this.dateInfo[0].day : '';
    },
    BX24Domain() {
      return this.$store.getters.getBX24Domain();
    },
  },
  methods: {
    created(value) {
      return moment(value.CALL_START_DATE).format('HH:mm');
    },
    duration(value) {
      var sec = value.CALL_DURATION;
      var result = `${sec}с`;
      var min = Math.floor(sec / 60);
      if (min > 0) {
        sec = sec % 60;
        result = `${min}м`;
        if (sec > 0)
          result = result + ` ${sec}с`;
      }
      return result;
    },
    file(value) {
      return `https://${this.BX24Domain.domain}/disk/downloadFile/${value.RECORD_FILE_ID}/?&ncc=1`;
    },
  },
  mixins: [fitSize],
}
</script>

<style scoped>
.card {
  padding: 20px;
}
</style>
