<template>
  <!-- create modal -->
  <div class="modal fade" id="createStatTemplateModal" tabindex="-1" role="dialog" aria-labelledby="createStatTemplateModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createStatTemplateModalLabel">Добавить статистику для <b>{{ postActive.PROPERTY_VALUES.NAME }}</b></h5>
          <button type="button" class="close" ref="statTemplatecreateclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <label for="name">Название</label>
              <input type="text" class="form-control shadow-none" id="name" placeholder="Название статистики" v-model="name">
            </div>
            <div class="form-group">
              <label for="type">Тип</label>
              <select class="form-control shadow-none" id="type" v-model="type">
                <option value="0">Прямая</option>
                <option value="1">Обратная</option>
              </select>
            </div>
            <div class="form-group">
              <label for="range">Диапазон</label>
              <select class="form-control shadow-none" id="range" v-model="range">
                <option value="0">День</option>
                <option value="1">Неделя</option>
              </select>
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="button" class="btn btn-success shadow-none float-right" @click="create">Добавить</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as STATTEMPLATE } from '../models/stattemplate.js';

export default {
  props: {
    postActive: Object,
  },
  data() {
    return {
      name: '',
      type: 0,
      range: 0,
      error: '',
    }
  },
  methods: {
    create() {
      this.error = '';
      if (this.name.trim().length > 0) {
        var props = {
          POST_ID: this.postActive.ID,
          NAME: this.name,
          TYPE: this.type,
          RANGE: this.range,
        }
        STATTEMPLATE.create(props).then((result) => {
          STATTEMPLATE.get({ID: result.answer.result}).then(statTemplate => {
            this.$emit('statTemplateCreated', statTemplate[0]);
            this.closeModal();
          })
        });
      } else {
        this.error = 'введите название статистики';
      }
    },
    closeModal() {
      this.$refs.statTemplatecreateclose.click();
      this.name = '';
      this.type = 0;
      this.range = 0;
    },
  },
}
</script>
