<template>
  <!-- edit modal -->
  <div class="modal fade" id="editStatTemplateModal" tabindex="-1" role="dialog" aria-labelledby="editStatTemplateModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editStatTemplateModalLabel">Редактировать статистику {{ statTemplateActive.PROPERTY_VALUES.NAME }} для <b>{{ postActive.PROPERTY_VALUES.NAME }}</b></h5>
          <button type="button" class="close" ref="statTemplateActiveeditclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <label for="name">Название</label>
              <input type="text" class="form-control shadow-none" id="name" placeholder="Название статистики" v-model="statTemplateActive.PROPERTY_VALUES.NAME">
            </div>
            <div class="form-group">
              <label for="type">Тип</label>
              <select class="form-control shadow-none" id="type" v-model="statTemplateActive.PROPERTY_VALUES.TYPE">
                <option value="0">Прямая</option>
                <option value="1">Обратная</option>
              </select>
            </div>
            <div class="form-group">
              <label for="range">Диапазон</label>
              <select class="form-control shadow-none" id="range" v-model="statTemplateActive.PROPERTY_VALUES.RANGE">
                <option value="0">День</option>
                <option value="1">Неделя</option>
              </select>
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="button" class="btn btn-success shadow-none float-right" @click="edit">Сохранить</button>
              <button type="button" class="btn btn-danger shadow-none float-right mr-2" @click="del">Удалить</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as STATTEMPLATE } from '../models/stattemplate.js';

export default {
  props: {
    statTemplateActive: Object,
    postActive: Object,
  },
  data() {
    return {
      error: '',
    }
  },
  methods: {
    edit() {
      this.error = '';
      if (this.statTemplateActive.PROPERTY_VALUES.NAME.trim().length > 0) {
        var props = {
          NAME: this.statTemplateActive.PROPERTY_VALUES.NAME,
          TYPE: this.statTemplateActive.PROPERTY_VALUES.TYPE,
          RANGE: this.statTemplateActive.PROPERTY_VALUES.RANGE,
        }
        STATTEMPLATE.update(this.statTemplateActive.ID, props).then(() => {
          STATTEMPLATE.get({ID: this.statTemplateActive.ID}).then(statTemplateActive => {
            this.$emit('statTemplateSaved', statTemplateActive[0]);
            this.closeModal();
          })
        });
      } else {
        this.error = 'введите название статистики';
      }
    },
    del() {
      STATTEMPLATE.del(this.statTemplateActive.ID).then(() => {
        this.$emit('statTemplateDeleted', this.statTemplateActive);
        this.closeModal();
      });
    },
    closeModal() {
      this.$refs.statTemplateActiveeditclose.click();
    },
  },
}
</script>
