<template>
  <div class="user" v-if="u">
    <img class="rounded float-left mr-2 mt-1" :class="{ 'blank-avatar' : !u.PERSONAL_PHOTO }" :src="u.PERSONAL_PHOTO">
    {{ u.LAST_NAME }}<br>{{ u.NAME }}
  </div>
</template>

<script>
export default {
  props: {
    user: String,
  },
  computed: {
    users() {
      return this.$store.getters.getBX24Users();
    },
    u() {
      var u = null;
      this.users.forEach(user => {
        if (this.user == user.ID)
          u = user;
      });
      return u;
    },
  },
}
</script>

<style scoped>
img {
  height: 39px;
  width: 39px;
  object-fit: cover;
  overflow: hidden;
}
.user {
  width: 200px;
  font-size: 15px;
}
</style>
