<template>
  <!-- edit value modal -->
  <div class="modal fade" id="editStatValueModal" tabindex="-1" role="dialog" aria-labelledby="editStatValueModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editStatValueModal">{{ this.statActive.PROPERTY_VALUES.NAME }} - {{ date(statValueActive.PROPERTY_VALUES.DATE) }}</h5>
          <button type="button" class="close" ref="statvalueeditclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <input type="text" class="form-control shadow-none" id="value" placeholder="Значение" v-model="statValueActive.PROPERTY_VALUES.VALUE">
              <div v-if="statValueActive.ID != null">
                <span class="badge badge-secondary">создано {{ datetime(statValueActive.DATE_CREATE) }} {{ user(statValueActive.CREATED_BY) }}</span>
                <span v-if="statValueActive.TIMESTAMP_X != statValueActive.DATE_CREATE" class="badge badge-warning">изменено {{ datetime(statValueActive.TIMESTAMP_X) }} {{ user(statValueActive.MODIFIED_BY) }}</span>
              </div>
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="submit" class="btn btn-success shadow-none float-right" @click.prevent="save">Сохранить</button>
              <button v-if="statValueActive.ID" type="button" class="btn btn-danger shadow-none float-left mr-2" @click="del">Удалить</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as STATVALUE } from '../models/statvalue.js';
var moment = require('moment');

export default {
  props: {
    statActive: Object,
    statValueActive: Object,
    error: String,
  },
  computed: {
    users() {
      return this.$store.getters.getBX24Users();
    },
  },
  methods: {
    date(date) {
      if (date)
        return `${date.toString().substring(6,8)}.${date.toString().substring(4,6)}.${date.toString().substring(0,4)}`;
      else
        return '';
    },
    datetime(date) {
      return `${moment(date).format('DD.MM.YYYY HH:mm')}`;
    },
    user(userId) {
      var u = null;
      this.users.forEach(user => {
        if (userId == user.ID)
          u = user;
      });
      return u == null ? '' : `${u.NAME} ${u.LAST_NAME}`;
    },
    save() {
      this.$emit('statValueErrorUpdate', '');
      var value = Number.parseFloat(this.statValueActive.PROPERTY_VALUES.VALUE);
      if (!isNaN(value)) {
        var props = {
          DATE: this.statValueActive.PROPERTY_VALUES.DATE,
          VALUE: value,
        }
        if (this.statValueActive.ID == null) {
          props.STAT_ID = this.statActive.ID;
          STATVALUE.create(props).then((result) => {
            STATVALUE.get({ID: result.answer.result}).then(statvalue => {
              this.$emit('statValueCreated', statvalue[0]);
              this.closeModal();
            })
          });
        } else {
          STATVALUE.update(this.statValueActive.ID, props).then(() => {
            STATVALUE.get({ID: this.statValueActive.ID}).then(statvalue => {
              this.$emit('statValueSaved', statvalue[0]);
              this.closeModal();
            })
          });
        }
      } else {
        this.$emit('statValueErrorUpdate', 'введите корректное значение');
      }
    },
    del() {
      STATVALUE.del(this.statValueActive.ID).then(() => {
        this.statValueActive.ID = null;
        this.statValueActive.PROPERTY_VALUES.VALUE = null;
        this.$emit('statValueDeleted', this.statValueActive);
        this.closeModal();
      });
    },
    closeModal() {
      this.$refs.statvalueeditclose.click();
    },
  },
}
</script>
