<template>
  <div class="card mb-2 p-0">
    <div class="card-body pl-0 pr-0 pb-1 pt-1">
      <div class="row">
        <div class="col">
          <button v-if="user.ADMIN" type="button" class="btn btn-outline-secondary shadow-none mt-1 float-right" data-toggle="modal" data-target="#editStatTemplateModal" @click="$emit('statTemplateSelected', statTemplate, post)"><i class="fa fa-pencil"></i></button>
          <div>{{ statTemplate.PROPERTY_VALUES.NAME }}</div>
          <span class="badge badge-info mr-1">{{ strType(statTemplate.PROPERTY_VALUES.TYPE) }}</span>
          <span class="badge badge-info">{{ strRange(statTemplate.PROPERTY_VALUES.RANGE) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fitSize } from '../mixins/fitSize';

export default {
  props: {
    statTemplate: Object,
    post: Object,
  },
  computed: {
    user() {
      return this.$store.getters.getBX24User();
    },
  },
  methods: {
    strType(type) {
      switch(type) {
        case'0':
          return 'прямая';
        case '1':
          return 'обратная';
      }
    },
    strRange(range) {
      switch(range) {
        case '0':
          return 'ежедневная';
        case '1':
          return 'еженедельная';
      }
    },
  },
  mixins: [fitSize],
}
</script>

<style scoped>
.card {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
</style>
