<template>
  <!-- edit modal -->
  <div class="modal fade" id="editStatModal" tabindex="-1" role="dialog" aria-labelledby="editStatModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editStatModalLabel">Редактировать статистику</h5>
          <button type="button" class="close" ref="stateditclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <label for="name">Название</label>
              <input type="text" class="form-control shadow-none" id="name" placeholder="Название статистики" v-model="statActive.PROPERTY_VALUES.NAME">
            </div>
            <div class="form-group">
              <label for="type">Тип</label>
              <select class="form-control shadow-none" id="type" v-model="statActive.PROPERTY_VALUES.TYPE">
                <option value="0">Прямая</option>
                <option value="1">Обратная</option>
              </select>
            </div>
            <div class="form-group">
              <label for="range">Диапазон</label>
              <select class="form-control shadow-none" id="range" v-model="statActive.PROPERTY_VALUES.RANGE">
                <option value="0">День</option>
                <option value="1">Неделя</option>
              </select>
            </div>
            <div class="form-group">
              <label for="post">Должность</label>
              <select class="form-control shadow-none" id="post" v-model="statActive.PROPERTY_VALUES.POST_ID">
                <option value=""></option>
                <option v-for="p in posts" :key="p.ID" :value="p.ID">{{ p.PROPERTY_VALUES.NAME }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="user">Сотрудник</label><br>
              <div class="row" v-if="statActive.PROPERTY_VALUES.USER_ID && statActive.PROPERTY_VALUES.USER_ID != 'undefined'">
                <div class="col-12">
                  <User class="mb-1 d-inline-block" :user="statActive.PROPERTY_VALUES.USER_ID"/>
                  <button type="button" class="btn btn-danger align-top ml-2 shadow-none mt-1" aria-label="Close" @click="updateUser('')">X</button>
                </div>
              </div>
              <button type="button" class="btn shadow-none btn-info mt-1" @click="selectUser">Назначить</button>
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="button" class="btn btn-success shadow-none float-right" @click="save">Сохранить</button>
              <button type="button" class="btn btn-danger shadow-none float-left mr-2" @click="del">Удалить</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as STAT } from '../models/stat.js';
import User from '../components/User';

export default {
  props: {
    statActive: Object,
  },
  data() {
    return {
      error: '',
    }
  },
  computed: {
    posts() {
      return this.$store.getters.getPosts();
    },
  },
  methods: {
    selectUser() {
      window.BX24.selectUser(this.updateUser);
    },
    updateUser(user) {
      this.statActive.PROPERTY_VALUES.USER_ID = user.id;
    },
    save() {
      this.error = '';
      if (this.statActive.PROPERTY_VALUES.NAME.trim().length > 0) {
        var props = {
          USER_ID: this.statActive.PROPERTY_VALUES.USER_ID,
          POST_ID: this.statActive.PROPERTY_VALUES.POST_ID,
          NAME: this.statActive.PROPERTY_VALUES.NAME,
          TYPE: this.statActive.PROPERTY_VALUES.TYPE,
          RANGE: this.statActive.PROPERTY_VALUES.RANGE,
        }
        STAT.update(this.statActive.ID, props).then(() => {
          STAT.get({ID: this.statActive.ID}).then(stat => {
            this.$emit('statSaved', stat[0]);
            this.closeModal();
          })
        });
      } else {
        this.error = 'введите название статистики';
      }
    },
    del() {
      STAT.del(this.statActive.ID).then(() => {
        this.$emit('statDeleted', this.statActive);
        this.closeModal();
      });
    },
    closeModal() {
      this.$refs.stateditclose.click();
    },
  },
  components: {
    User,
  },
}
</script>
