function BX24callMethod(method, params) {
  return new Promise((resolve, reject) => {
    window.BX24.callMethod(method, params, function(result) {
      if (result.answer.error)
        reject(result.answer.error);
      else
        resolve(result);
    });
  });
}

function BX24callBatch(data) {
  return new Promise((resolve) => {
    var batch_size = 0;
    var batches = [];
    var batch = [];
    for (let i = 0; i < data.length; i++) {
      if (batch_size >= 50) {
        batches.push(new Promise((resolve) => {
          window.BX24.callBatch(batch, function(result) {
            var data = [];
            result.forEach(element => {
              data = data.concat(element.answer.result);
            });
            resolve(data);
          });
        }));
        batch_size = 0;
        batch = [];
      }
      batch.push([data[i][0], data[i][1]]);
      batch_size++;
    }
    if (batch_size > 0 && batch_size < 50) {
      batches.push(new Promise((resolve) => {
        window.BX24.callBatch(batch, function(result) {
          var data = [];
          result.forEach(element => {
            data = data.concat(element.answer.result);
          });
          resolve(data);
        });
      }));
    }
    Promise.all(batches).then(result => {
      var data = [];
      result.forEach(element => {
        data = data.concat(element);
      });
      resolve(data);
    });
  });
}

function BX24getList(method, params) {
  return new Promise((resolve, reject) => {
    window.BX24.callMethod(method, params, function(result) {
      if (result.answer.error)
        reject(result.answer.error);
      else {
        var total = result.answer.total;
        if (total > 50) {
          var batches = [];
          var from = 0;
          while (from < total) {
            var batch_size = 0;
            var batch = [];
            while (batch_size < 50 && from < total) {
              var args = {...params};
              args.start = from;
              batch.push([method, args]);
              from += 50;
              batch_size++;
            }
            batches.push(new Promise((resolve) => {
              window.BX24.callBatch(batch, function(result) {
                var data = [];
                result.forEach(element => {
                  data = data.concat(element.answer.result);
                });
                resolve(data);
              });
            }));
          }
          Promise.all(batches).then(result => {
            var data = [];
            result.forEach(element => {
              data = data.concat(element);
            });
            if (data.length > 0) {
              if (data[0].hasOwnProperty('CREATED'))
                data.sort((a, b) => a.CREATED < b.CREATED);
              else if (data[0].hasOwnProperty('CALL_START_DATE'))
                data.sort((a, b) => a.CALL_START_DATE < b.CALL_START_DATE);
              else if (data[0].hasOwnProperty('DATE_CREATE'))
                data.sort((a, b) => a.DATE_CREATE < b.DATE_CREATE);
              else if (data[0].hasOwnProperty('ID'))
                data.sort((a, b) => a.ID < b.ID);
            }
            resolve(data);
          });
        } else {
          var data = result.answer.result;
          if (data.length > 0) {
            if (data[0].hasOwnProperty('CREATED'))
              data.sort((a, b) => a.CREATED < b.CREATED);
            else if (data[0].hasOwnProperty('CALL_START_DATE'))
              data.sort((a, b) => a.CALL_START_DATE < b.CALL_START_DATE);
            else if (data[0].hasOwnProperty('DATE_CREATE'))
              data.sort((a, b) => a.DATE_CREATE < b.DATE_CREATE);
            else if (data[0].hasOwnProperty('ID'))
              data.sort((a, b) => a.ID < b.ID);
          }
          resolve(data);
        }
      }
    });
  });
}

function setUserOptions(options) {
  return BX24callMethod('user.option.set', {'options': {'userOptions': options}});
}

function getUserOptions() {
  return BX24callMethod('user.option.get');
}

function getTables() {
  return BX24getList('entity.get', {});
}

function getTablesProperties(tables) {
  return new Promise((resolve) => {
    var batch = {};
    tables.forEach(table => batch[table.NAME] = {method: 'entity.item.property.get', params: {'ENTITY': table.NAME}});
    window.BX24.callBatch(batch, function(result) {
      resolve(result);
    });
  });
}

function getTableProperties(entity) {
  return BX24getList('entity.item.property.get', {
    ENTITY: entity,
  });
}

function createTable(model) {
  return new Promise((resolve, reject) => {
    var batch = [];
    batch.push(['entity.add', {'ENTITY': model.NAME, 'NAME': model.NAME, 'ACCESS': {'AU': 'W'}}]);
    model.PROPERTIES.forEach(prop => {
      batch.push(['entity.item.property.add',
        {'ENTITY': model.NAME, 'PROPERTY': prop.PROPERTY, 'NAME': prop.NAME, 'TYPE': prop.TYPE}]);
    });
    window.BX24.callBatch(batch, function(result) {
      var error = false;
      result.forEach(item => {
        if (item.answer.error)
          error = item.answer.error;
      });
      if (error)
        reject(error);
      else
        resolve(result);
    });
  });
}

function createProperties(model, properties) {
  return new Promise((resolve, reject) => {
    var batch = [];
    properties.forEach(prop => {
      batch.push(['entity.item.property.add',
        {'ENTITY': model.NAME, 'PROPERTY': prop.PROPERTY, 'NAME': prop.NAME, 'TYPE': prop.TYPE}]);
    });
    window.BX24.callBatch(batch, function(result) {
      var error = false;
      result.forEach(item => {
        if (item.answer.error)
          error = item.answer.error;
      });
      if (error)
        reject(error);
      else
        resolve(result);
    });
  });
}

function deleteTable(model) {
  return new Promise((resolve, reject) => {
    window.BX24.callMethod('entity.delete', {
      ENTITY: model.NAME,
    }, function(result) {
      if (result.answer.error)
        reject(result.answer.error);
      else
        resolve(result);
    });
  });
}

function getRows(data) {
  return BX24getList('entity.item.get', {
    ENTITY: data.ENTITY,
    SORT: data.SORT,
    FILTER: data.FILTER,
  });
}

function createRow(data) {
  if(Array.isArray(data.PROPERTIES)) {
    var batch = [];
    data.PROPERTIES.forEach(props => {
      batch.push(['entity.item.add', {
        ENTITY: data.ENTITY,
        NAME: data.NAME,
        PROPERTY_VALUES: props,
      }]);
    })
    return BX24callBatch(batch);
  } else {
    return BX24callMethod('entity.item.add', {
      ENTITY: data.ENTITY,
      NAME: data.NAME,
      PROPERTY_VALUES: data.PROPERTIES,
    });
  }
}

function updateRow(data) {
  if(Array.isArray(data.ID)) {
    var batch = [];
    data.ID.forEach(id => {
      batch.push(['entity.item.update', {
        ENTITY: data.ENTITY,
        ID: id,
        PROPERTY_VALUES: data.PROPERTIES,
      }]);
    })
    return BX24callBatch(batch);
  } else {
    return BX24callMethod('entity.item.update', {
      ENTITY: data.ENTITY,
      ID: data.ID,
      PROPERTY_VALUES: data.PROPERTIES,
    });
  }
}

function deleteRow(data) {
  if(Array.isArray(data.ID)) {
    var batch = [];
    data.ID.forEach(id => {
      batch.push(['entity.item.delete', {
        ENTITY: data.ENTITY,
        ID: id,
      }]);
    })
    return BX24callBatch(batch);
  } else {
    return BX24callMethod('entity.item.delete', {
      ENTITY: data.ENTITY,
      ID: data.ID,
    });
  }
}

function getCalls(from, to, duration=0) {
  return BX24getList('voximplant.statistic.get', {
    order: { 'CALL_START_DATE': 'DESC' },
    filter: {
      '>=CALL_START_DATE': from + ' 00:00:00',
      '<=CALL_START_DATE': to + ' 23:59:59',
      '>=CALL_DURATION': duration,
    },
    select: [ 'ID', 'CALL_START_DATE', 'CALL_TYPE', 'DURATION', 'CALL_DURATION', 'PORTAL_USER_ID', 'RECORD_FILE_ID', 'COMMENT', 'CRM_ENTITY_ID', 'CRM_ENTITY_TYPE', 'PHONE_NUMBER', 'CRM_ACTIVITY_ID' ],
  });
}

function getCallNote(call) {
  var ownerTypeId = call.CRM_ENTITY_TYPE;
  switch(call.CRM_ENTITY_TYPE) {
    case "LEAD":
      ownerTypeId = 1;
      break;
    case "DEAL":
      ownerTypeId = 2;
      break;
    case "CONTACT":
      ownerTypeId = 3;
      break;
    case "COMPANY":
      ownerTypeId = 4;
      break;
    case "INVOICE":
      ownerTypeId = 5;
      break;
    case "SMART_INVOICE":
      ownerTypeId = 31;
      break;
    case "QUOTE":
      ownerTypeId = 7;
      break;
    case "REQUISITE":
      ownerTypeId = 8;
      break;
    case "DYNAMIC_130":
      ownerTypeId = 13;
      break;
  }
  return BX24callMethod("crm.timeline.note.get", {
    "ownerId": call.CRM_ENTITY_ID,
    "ownerTypeId": ownerTypeId,
    "itemId": call.CRM_ACTIVITY_ID,
    "itemType": 2,
  })
}

function getCompanies(ids) {
  return BX24getList('crm.company.list', {
    order: { 'DATE_CREATE': 'DESC' },
    filter: { ID: ids },
    select: [ 'DATE_CREATE', 'ID', 'TITLE', 'COMPANY_TYPE' ],
  });
}

function getDeals(from, to) {
  return BX24getList('crm.deal.list', {
    order: { 'DATE_CREATE': 'DESC' },
    filter: {
      '>=DATE_CREATE': from + ' 00:00:00',
      '<=DATE_CREATE': to + ' 23:59:59',
    },
    select: [ 'DATE_CREATE', 'ID', 'TITLE', 'STAGE_ID', 'OPPORTUNITY', 'CREATED_BY_ID' ],
  });
}

function getNotes(from, to) {
  return BX24getList('crm.activity.list', {
    order: { 'CREATED': 'DESC' },
    filter: {
      '>=CREATED': from + ' 00:00:00',
      '<=CREATED': to + ' 23:59:59',
      "TYPE_ID": 2,
      "DIRECTION": 2,
      "COMPLETED": "Y",
      "RESULT_STREAM": 0,
    },
    select: [ 'ID', 'START_TIME', 'END_TIME', 'CREATED', 'SUBJECT', 'AUTHOR_ID', 'DESCRIPTION', "OWNER_TYPE_ID", "OWNER_ID" ],
  });
}

function getCallsFake(from, to) {
  return BX24getList('crm.activity.list', {
    order: { 'START_TIME': 'DESC' },
    filter: {
      '>=START_TIME': from + ' 00:00:00',
      '<=START_TIME': to + ' 23:59:59',
      "TYPE_ID": 2,
      "DIRECTION": 2,
      "COMPLETED": "Y",
      "RESULT_STREAM": 0,
    },
    select: [ 'ID', 'START_TIME', 'END_TIME', 'SUBJECT', 'AUTHOR_ID', 'DESCRIPTION', "OWNER_TYPE_ID", "OWNER_ID" ],
  });
}

function getCallsFuture(from, to) {
  return BX24getList('crm.activity.list', {
    order: { 'CREATED': 'DESC' },
    filter: {
      '>=CREATED': from + ' 00:00:00',
      '<=CREATED': to + ' 23:59:59',
      "TYPE_ID": 2,
      "DIRECTION": 2,
      "COMPLETED": "N",
      "RESULT_STREAM": 0,
    },
    select: [ 'ID', 'START_TIME', 'END_TIME', 'CREATED', 'SUBJECT', 'AUTHOR_ID', 'DESCRIPTION', "OWNER_TYPE_ID", "OWNER_ID" ],
  });
}

function getQuotes(from, to) {
  return BX24getList('crm.quote.list', {
    order: { 'DATE_CREATE': 'DESC' },
    filter: {
      '>=DATE_CREATE': from + ' 00:00:00',
      '<=DATE_CREATE': to + ' 23:59:59',
    },
    select: [ 'ID', 'DATE_CREATE', 'CREATED_BY_ID' ],
  });
}

function getDepartments() {
  return BX24getList('department.get');
}

function getFile(id) {
  return BX24callMethod('disk.file.get', {'id': id});
}

function getContactCompanyList(ids) {
  var data = [];
  ids.forEach(id => {
    data.push(['crm.contact.company.items.get', {'id': id}]);
  })
  return new Promise((resolve) => {
    var batch_size = 0;
    var batches = [];
    var batch = [];
    for (let i = 0; i < data.length; i++) {
      if (batch_size >= 50) {
        batches.push(new Promise((resolve) => {
          window.BX24.callBatch(batch, function(result) {
            var data = [];
            result.forEach(element => {
              data = data.concat(element);
            });
            resolve(data);
          });
        }));
        batch_size = 0;
        batch = [];
      }
      batch.push([data[i][0], data[i][1]]);
      batch_size++;
    }
    if (batch_size > 0 && batch_size < 50) {
      batches.push(new Promise((resolve) => {
        window.BX24.callBatch(batch, function(result) {
          var data = [];
          result.forEach(element => {
            data = data.concat(element);
          });
          resolve(data);
        });
      }));
    }
    Promise.all(batches).then(result => {
      var data = [];
      result.forEach(element => {
        data = data.concat(element);
      });
      resolve(data);
    });
  });
}

export {
  getTables,
  getTablesProperties,
  getTableProperties,
  createTable,
  createProperties,
  deleteTable,
  getRows,
  createRow,
  updateRow,
  deleteRow,
  getCalls,
  getCompanies,
  getDeals,
  getNotes,
  getCallsFake,
  getCallsFuture,
  getQuotes,
  getDepartments,
  setUserOptions,
  getUserOptions,
  getFile,
  getContactCompanyList,
  getCallNote,
}
