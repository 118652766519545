<template>
  <div class="card mr-2 mb-2 p-0" :style="{width: width + 40 + 'px'}">
    <div class="card-body">
      <h5 class="card-title">
        <div class="d-inline align-middle">{{ stat.NAME }}</div>
        <span class="badge badge-danger float-right cursor-pointer" @click="$emit('statWidgetRemoved', stat)"><i class="fa fa-ban"></i></span>
      </h5>
      <div class="row">
        <div class="col">
          <div class="float-left text-left mt-1">
          <span class="badge badge-primary mt-1">{{ strPost() }}</span><br>
          <span class="badge badge-primary">{{ strStatTemplate(stat.STATTEMPLATE_ID) }}</span>
          </div>
        </div>
        <div class="col">
          <button type="button" class="btn btn-outline-secondary shadow-none btn-lg float-right ml-2 mt-1" data-toggle="modal" data-target="#editStatWidgetModal" @click="$emit('statWidgetSelected', stat)"><i class="fa fa-pencil"></i></button>
          <div class="float-right text-right mt-1">
            <span class="badge badge-info">{{ strType(stat.TYPE) }}</span><br>
            <span class="badge badge-info">{{ strRange(stat.RANGE) }}</span>
          </div>
        </div>
      </div>
      <div class="stat-wrapper mt-2" :style="{width: width + 'px', height: height + 'px'}">
        <Chart :chartData="data" :options="options" :style="{width: width + 'px', height: height + 'px'}"/>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '../components/Chart';
import { fitSize } from '../mixins/fitSize';
var moment = require('moment');

export default {
  props: {
    stat: Object,
  },
  data() {
    return {
      values: [],
      colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#637939', '#8c6d31', '#393b79', '#31a354', '#0a0a0a'],
    }
  },
  computed: {
    posts() {
      return this.$store.getters.getPosts();
    },
    post() {
      var post = null;
      this.posts.forEach(p => {
        if (this.stat.POST_ID == p.ID)
          post = p;
      });
      return post;
    },
    stats() {
      return this.$store.getters.getStats().stats;
    },
    statvalues() {
      return this.$store.getters.getStats().statvalues;
    },
    from() {
      return this.$store.getters.getDashboard().from;
    },
    to() {
      return this.$store.getters.getDashboard().to;
    },
    filterModified() {
      return this.$store.getters.getDashboard().filterModified;
    },
    user() {
      return this.$store.getters.getBX24User();
    },
    users() {
      return this.$store.getters.getBX24Users();
    },
    weekStart() {
      return this.$store.getters.getOptions().weekStart;
    },
    width() {
      return Number.parseInt(this.$store.getters.getDashboardCurrent().statsWidth);
    },
    height() {
      return Number.parseInt(this.$store.getters.getDashboardCurrent().statsHeight);
    },
    weekends() {
      return this.$store.getters.getOptions().weekends;
    },
    showWeekends() {
      return this.$store.getters.getDashboardCurrent().showWeekends;
    },
    days() {
      var from = moment(this.from);
      var to = moment(this.to);
      var days = to.diff(from, 'd');
      var values = [];
      for (let i = 0; i <= days; i++) {
        var date = from.format('YYYY-MM-DD');
        values.push(date);
        from.add(1, 'd');
      }
      return values;
    },
    weeks() {
      var values = [];
      var from = moment(this.from);
      if (!this.filterModified)
        from.subtract(9, 'w');
      while (from.day() != this.weekStart)
        from.add(1, 'd');
      var to = moment(this.to);
      var weeks = to.diff(from, 'w');
      for (var i = 0; i <= weeks; i++) {
        values.push(from.format('YYYY-MM-DD'));
        from.add(1, 'w');
      }
      return values;
    },
    labels() {
      var values = [];
      this.days.forEach(date => {
        if (this.isDateShow(date)) {
          values.push(date);
        }
      })
      return values;
    },
    valuesFiltered() {
      var values = [];
      if (this.stat.RANGE == 0) {
        this.values.forEach(userValues => {
          var userValuesFiltered = {
            user: userValues.user,
            days: [],
            values: [],
            value: [],
          };
          for (let i = 0; i < this.labels.length; i++) {
            for (let j = i; j < userValues.days.length; j++) {
              if (this.labels[i] == userValues.days[j]) {
                userValuesFiltered.days.push(userValues.days[j]);
                userValuesFiltered.values.push(userValues.values[j]);
                userValuesFiltered.value.push(userValues.value[j]);
                break;
              }
            }
          }
          values.push(userValuesFiltered);
        })
      } else {
        values = this.values;
      }
      return values;
    },
    datasets() {
      var datasets = [];
      var color;
      for (let i = 0; i < this.valuesFiltered.length; i++) {
        if (i < this.colors.length)
          color = this.colors[i];
        else
          color = '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1,6);
        datasets.push({
          data: this.valuesFiltered[i].value,
          days: this.valuesFiltered[i].days,
          values: this.valuesFiltered[i].values,
          label: this.userName(this.valuesFiltered[i].user),
          fill: false,
          hitRadius: 20,
          borderColor: color,
          backgroundColor: color,
        });
      }
      return datasets;
    },
    data() {
      return {
        labels: this.datasets.length > 0 ? this.datasets[0].days : [],
        datasets: this.datasets,
      };
    },
    options() {
      return {
        title: {
          display: false,
        },
        legend: {
          display: true,
        },
        hover: {
          onHover: function(e) {
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = 'pointer';
            else e.target.style.cursor = 'default';
          }
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            position: this.stat.TYPE == 1 ? 'top' : 'bottom',
            stacked: true,
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              reverse: this.stat.TYPE == 1,
            },
          }],
        },
        tooltips: {
          mode: 'label',
        },
        onClick: this.onClick,
      };
    },
  },
  methods: {
    strType(type) {
      switch(type) {
        case'0':
          return 'прямая';
        case '1':
          return 'обратная';
      }
    },
    strRange(range) {
      switch(range) {
        case '0':
          return 'ежедневная';
        case '1':
          return 'еженедельная';
      }
    },
    strPost() {
      if (this.post != null)
        return this.post.PROPERTY_VALUES.NAME;
      else
        return '';
    },
    strStatTemplate(id) {
      var statTemplate = this.post.statTemplates.filter(st => st.ID == id);
      if (statTemplate.length > 0)
        return statTemplate[0].PROPERTY_VALUES.NAME;
      else
        return '';
    },
    date(date) {
      return `${date.toString().substring(6,8)}.${date.toString().substring(4,6)}`;
    },
    isDateShow(date) {
      var result = false;
      var isWeekend = false;
      this.weekends.forEach((weekend) => {
        if (moment(date).day() == weekend) {
          isWeekend = true;
        }
      });
      if (!(this.stat.RANGE == 0 && !this.showWeekends && isWeekend)) {
        result = true;
      }
      return result;
    },
    userName(userId) {
      var u = null;
      this.users.forEach(user => {
        if (userId == user.ID)
          u = user;
      });
      if (u != null)
        return `${u.LAST_NAME} ${u.NAME}`;
      else
        return 'не найден';
    },
    updateData() {
      this.values = [];
      this.stat.STATS.forEach(statId => {
        var stat = this.stats.filter(x => x.ID == statId)[0];
        var userValues = {
          user: stat.PROPERTY_VALUES.USER_ID,
          days: [],
          values: [],
          value: [],
        };
        var days = [];
        if (this.stat.RANGE == 0)
          days = this.days;
        else
          days = this.weeks;
        days.forEach(date => {
          var userData = this.statvalues.filter(x => x.PROPERTY_VALUES.STAT_ID == statId).filter(x => x.PROPERTY_VALUES.DATE == date.split('-').join(''));
          userValues.days.push(date);
          userValues.values.push(userData);
          userValues.value.push(userData.length > 0 ? userData[0].PROPERTY_VALUES.VALUE : '');
        })
        this.values.push(userValues);
      })
    },
    onClick(point, event) {
      if (event.length > 0) {
        var index = event[0]._index;
        this.$emit('statWidgetDateInfoSelected', this.stat, this.datasets, index);
      }
    },
  },
  mounted() {
    if (this.$store.getters.getDashboard().loaded) {
      this.updateData();
    }
  },
  components: {
    Chart,
  },
  mixins: [fitSize],
}
</script>
