<template>
  <div class="card post mr-2 mb-2 p-0">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col mt-1">
          <b>{{ post.PROPERTY_VALUES.NAME }}</b>
        </div>
        <div class="col">
          <button v-if="user.ADMIN" type="button" class="btn btn-outline-secondary shadow-none float-right" data-toggle="modal" data-target="#editPostModal" @click="$emit('postSelected', post)"><i class="fa fa-pencil"></i></button>
        </div>
      </div>
      <div class="">
        <StatTemplate v-for="statTemplate in post.statTemplates" :key="statTemplate.ID" :post="post" :statTemplate="statTemplate" @statTemplateSelected="statTemplateSelected"/>
        <button class="btn btn-info shadow-none w-100 mt-2"  data-toggle="modal" data-target="#createStatTemplateModal" @click="$emit('postSelected', post)"><i class="fa fa-plus"></i> добавить статистику</button>
      </div>
    </div>
  </div>
</template>

<script>
import { fitSize } from '../mixins/fitSize';
import StatTemplate from './StatTemplate';

export default {
  props: {
    post: Object,
  },
  computed: {
    user() {
      return this.$store.getters.getBX24User();
    },
  },
  methods: {
    statTemplateSelected(statTemplate, post) {
      this.$emit('statTemplateSelected', statTemplate, post);
    }
  },
  components: {
    StatTemplate,
  },
  mixins: [fitSize],
}
</script>

<style scoped>
.post {
  width: 300px;
}
</style>
