<template>
  <!-- edit modal -->
  <div class="modal fade" id="editPostModal" tabindex="-1" role="dialog" aria-labelledby="editPostModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editPostModalLabel">Редактировать должность</h5>
          <button type="button" class="close" ref="posteditclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <label for="name">Название</label>
              <input type="text" class="form-control shadow-none" id="name" placeholder="Название должности" v-model="postActive.PROPERTY_VALUES.NAME">
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="button" class="btn btn-success shadow-none float-right" @click="save">Сохранить</button>
              <button type="button" class="btn btn-danger shadow-none float-left" @click="del">Удалить</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as POST } from '../models/post.js';

export default {
  props: {
    postActive: Object,
  },
  data() {
    return {
      error: '',
    }
  },
  methods: {
    save() {
      this.error = '';
      if (this.postActive.PROPERTY_VALUES.NAME.trim().length > 0) {
        var props = {
          NAME: this.postActive.PROPERTY_VALUES.NAME,
        }
        POST.update(this.postActive.ID, props).then(() => {
          POST.get({ID: this.postActive.ID}).then(post => {
            this.$emit('postSaved', post[0]);
            this.closeModal();
          })
        });
      } else {
        this.error = 'введите название статистики';
      }
    },
    del() {
      POST.del(this.postActive.ID).then(() => {
        this.$emit('postDeleted', this.postActive);
        this.closeModal();
      });
    },
    closeModal() {
      this.$refs.posteditclose.click();
    },
  },
}
</script>
