<template>
  <div>
    <h2 class="pagetitle">
      <span class="mr-3">Отчеты</span>
      <button type="button" class="btn btn-info shadow-none mb-1" @click.prevent="filterModified = true; updateData()" :disabled="updating" title="Обновить данные">
        <i v-if="!updating" class="fa fa-refresh"></i>
        <i v-if="updating" class="fa fa-refresh fa-spin"></i>
      </button>

      <div class="d-inline-block align-middle ml-2 mb-1">
        <div class="form-inline">
          <input type="date" class="form-control shadow-none mr-2" id="date" v-model="date" required>
        </div>
      </div>
      <button v-if="$store.getters.getBX24User().ADMIN" type="button" class="btn btn-outline-secondary shadow-none float-right" data-toggle="button" :aria-pressed="showSettings" @click="showSettings = !showSettings" title="Показать настройки">
        <i class="fa fa-cog"></i>
      </button>
      <div class="d-inline-block align-middle ml-2 mb-1 float-right">
        <div class="form-inline">
          <label for="call_type" class="mr-2">звонки</label><br>
          <select class="form-control custom-select cursor-pointer shadow-none mr-2" id="call_type" v-model="call_type" @change="updateWatch">
            <option value="0">все ({{ callsCount("0") }})</option>
            <option value="1">исходящие ({{ callsCount("1") }})</option>
            <option value="2">входящие ({{ callsCount("2") }})</option>
          </select>
          <label for="company_type" class="mr-2">тип компании</label><br>
          <select class="form-control custom-select cursor-pointer shadow-none mr-2" id="company_type" v-model="company_type" @change="updateWatch">
            <option value="0">все ({{ companiesCount("0") }})</option>
            <option value="Перекуп">Перекуп ({{ companiesCount("Перекуп") }})</option>
            <option value="Проектировщик">Проектировщик ({{ companiesCount("Проектировщик") }})</option>
            <option value="Заказчик">Заказчик ({{ companiesCount("Заказчик") }})</option>
            <option value="Подрядчик">Подрядчик ({{ companiesCount("Подрядчик") }})</option>
            <option value="Подрядчик проект Цифровая Экономика">Подрядчик проект Цифровая Экономика ({{ companiesCount("Подрядчик проект Цифровая Экономика") }})</option>
            <option value="Поставщик">Поставщик ({{ companiesCount("Поставщик") }})</option>
          </select>
          <div class="form-group form-inline mr-2">
            <label for="call_duration_local" class="mr-2">длительность от, сек</label><br>
            <input type="number" class="form-control shadow-none mr-2" id="call_duration_local" v-model="call_duration_local">
          </div>
        </div>
      </div>
    </h2>

    <form class="mb-2" v-show="showSettings && $store.getters.getBX24User().ADMIN">
      <h4>Настройки для всех</h4>
      <label for="call_duration">Длительность звонков от, сек</label><br>
      <input type="number" class="form-control shadow-none mb-2" id="call_duration" v-model="call_duration" @change="updateCallDuration">
      <label for="user">Сотрудники</label><br>
      <div class="row">
        <div class="col-sm-6 col-md-4" v-for="user in options.watch.users" :key="user">
          <User class="mb-1 d-inline-block" :user="user"/>
          <button type="button" class="btn btn-danger align-top ml-2 shadow-none mt-1" aria-label="Close" @click="removeUser(user)">X</button>
        </div>
      </div>
      <button type="button" class="btn btn-info shadow-none mt-1 mb-2" @click="selectUsers">Выбрать</button>
      <br>
      <label for="moderators">Контроль качества</label><br>
      <div class="row">
        <div class="col-sm-6 col-md-4" v-for="moderator in options.watch.moderators" :key="moderator">
          <User class="mb-1 d-inline-block" :user="moderator"/>
          <button type="button" class="btn btn-danger align-top ml-2 shadow-none mt-1" aria-label="Close" @click="removeModerator(moderator)">X</button>
        </div>
      </div>
      <button type="button" class="btn btn-info shadow-none mt-1 mb-2" @click="selectModerators">Выбрать</button>
      <br>
      <label for="phones">Телефоны, не учитывающиеся в аналитике</label><br>
      <div class="row">
        <div class="col-sm-6 col-md-4" v-for="phone in options.watch.phones_exclude" :key="phone">
          <div class="mb-1 d-inline-block">{{ phone }}</div>
          <button type="button" class="btn btn-danger align-top ml-2 shadow-none mt-1" aria-label="Close" @click="removePhone(phone)">X</button>
        </div>
      </div>
      <input type="text" class="form-control shadow-none mb-2 mt-1" id="exclude_phone" v-model="exclude_phone">
      <button type="button" class="btn btn-info shadow-none mt-1" @click="addPhones">Добавить</button>
      <hr>
    </form>

    <div class="row mx-0 my-3 row-header">
      <div class="col"><b>Сотрудник</b></div>
      <div class="col"><b>Телефония</b></div>
      <div class="col"><b>Другая связь</b></div>
      <div class="col"><b>Запланированные звонки</b></div>
      <div class="col"><b>Коммерческие предложения</b></div>
    </div>
      
    <div class="accordion" id="accordion">
      <div class="card" v-for="user in data" :key="user.user" @click="onUserRowClick(user)">
        <div class="card-header" :id="'heading_' + user.user" type="button" data-toggle="collapse" :data-target="'#collapse_' + user.user" aria-expanded="false" :aria-controls="'collapse_' + user.user">
          <div class="row">
            <div class="col"><User :user="user.user"/></div>
            <div class="col justify-content-center align-self-center"><b>{{ user.calls_real }}</b></div>
            <div class="col justify-content-center align-self-center"><b>{{ user.calls_fake }}</b></div>
            <div class="col justify-content-center align-self-center"><b>{{ user.calls_future }}</b></div>
            <div class="col justify-content-center align-self-center"><b>{{ user.quotes }}</b></div>
          </div>
        </div>
        <div :id="'collapse_' + user.user" class="collapse" :aria-labelledby="'heading_' + user.user" data-parent="#accordion">
          <div class="card-body pt-0 pb-0">
            <div class="row border-bottom pb-3 pt-3" v-for="(row, index) in user.rows" :key="'row_' + index">
              <div class="col">
                <div v-if="row[0]">
                  {{ row[0] }}
                </div>
              </div>
              <div class="col">
                <div v-if="row[1]">
                  <div v-if="row[1].CALL_TYPE == '1'"><span class="badge badge-info">{{ callType(row[1].CALL_TYPE) }}</span><span class="badge badge-secondary ml-1">{{ row[1].PHONE_NUMBER }}</span></div>
                  <div v-if="row[1].CALL_TYPE == '2' || row[1].CALL_TYPE == '3'"><span class="badge badge-success">{{ callType(row[1].CALL_TYPE) }}</span><span class="badge badge-secondary ml-1">{{ row[1].PHONE_NUMBER }}</span></div>
                  <div v-if="row[1].COMMENT">{{ row[1].COMMENT }}</div>
                  <div v-if="!row[1].COMMENT"><span class="badge badge-warning">нет комментария</span></div>
                  <div v-if="!row[1].COMMENT_LOADED">подгрузка комментария...</div>
                  <div>
                    <b>{{ callDuration(row[1]) }}</b>
                    <i class="fa fa-file-audio-o cursor-pointer ml-3" @click="getAudioUrl(row[1])" aria-hidden="true"></i>
                  </div>
                  <div v-if="row[1].ID < 0">
                    <audio controls><source :src="row[1].RECORD_FILE_ID" type="audio/wav"></audio>
                  </div>
                  <div v-if="row[1].CRM_ENTITY_ID"><a :href="urlByOwner(row[1].CRM_ENTITY_TYPE, row[1].CRM_ENTITY_ID)" target="_blank">Перейти к {{ nameByOwner(row[1].CRM_ENTITY_TYPE, row[1].COMPANY_TYPE) }}</a></div>
                  <div v-if="row[1].CRM_ENTITY_ID && (row[1].CRM_ENTITY_TYPE == 'CONTACT' || row[1].CRM_ENTITY_TYPE == '3')"><a :href="companyUrlByContactId(row[1].CRM_ENTITY_ID)" target="_blank">Перейти к компании</a></div>
                  <div v-if="!row[1].CRM_ENTITY_ID"><span class="badge badge-danger">нет сущности</span></div>
                </div>
              </div>
              <div class="col">
                <div v-if="row[2]">
                  <b>{{ row[2].SUBJECT }}</b><br>
                  <div v-if="row[2].DESCRIPTION">{{ row[2].DESCRIPTION }}</div>
                  <div v-if="!row[2].DESCRIPTION"><span class="badge badge-warning">нет комментария</span></div>
                  <div v-if="row[2].OWNER_ID"><a :href="urlByOwner(row[2].OWNER_TYPE_ID, row[2].OWNER_ID)" target="_blank">Перейти к {{ nameByOwner(row[2].OWNER_TYPE_ID, row[2].COMPANY_TYPE) }}</a></div>
                  <div v-if="row[2].OWNER_ID && (row[2].OWNER_TYPE_ID == 'CONTACT' || row[2].OWNER_TYPE_ID == '3')"><a :href="companyUrlByContactId(row[2].OWNER_ID)" target="_blank">Перейти к компании</a></div>
                </div>
              </div>
              <div class="col">
                <div v-if="row[3]">
                  <b>{{ row[3].SUBJECT }}</b><br>
                  <div v-if="row[3].DESCRIPTION">{{ row[3].DESCRIPTION }}</div>
                  <div v-if="!row[3].DESCRIPTION"><span class="badge badge-warning">нет комментария</span></div>
                  <div>Запланировано на {{ moment(row[3].START_TIME).format('DD.MM.YYYY HH:mm') }}</div>
                  <div v-if="row[3].OWNER_ID"><a :href="urlByOwner(row[3].OWNER_TYPE_ID, row[3].OWNER_ID)" target="_blank">Перейти к {{ nameByOwner(row[3].OWNER_TYPE_ID, -1) }}</a></div>
                </div>
              </div>
              <div class="col">
                <div v-if="row[4]">
                  <a :href="quote(row[4])" target="_blank">Предложение №{{ row[4].ID }} от {{ moment(row[4].START_TIME).format('DD.MM.YYYY') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getCalls, getCallsFake, getCallsFuture, getQuotes, getCompanies, getFile, getContactCompanyList, getCallNote } from '../models/api';
import { fitSize } from '../mixins/fitSize';
import User from '../components/User';
var moment = require('moment');

export default {
  data() {
    return {
      date: this.$store.getters.getWatch().date,
      call_type: this.$store.getters.getWatch().call_type,
      company_type: this.$store.getters.getWatch().company_type,
      call_duration: this.$store.getters.getOptions().watch.call_duration,
      call_duration_local: this.$store.getters.getWatch().call_duration_local,
      updating: false,
      showSettings: false,
      moment: moment,
      contacts_ids: [],
      contact_companies: [],
      exclude_phone: '',
    }
  },
  computed: {
    BX24Domain() {
      return this.$store.getters.getBX24Domain();
    },
    isModerator() {
      let moderators = this.$store.getters.getModerators();
      let moderator = false;
      moderators.forEach(moderatorId => {
        if (moderatorId == this.$store.getters.getBX24User().ID)
          moderator = true;
      });
      return moderator;
    },
    watch() {
      return this.$store.getters.getWatch();
    },
    options() {
      return this.$store.getters.getOptions();
    },
    callsFiltered() {
      switch(this.call_type) {
        case "1":
          return this.watch.calls_real.filter(x => x.CALL_TYPE == "1");
        case "2":
          return this.watch.calls_real.filter(x => x.CALL_TYPE == "2" || x.CALL_TYPE == "3");
        default:
          return this.watch.calls_real;
      }
    },
    realCallsFilteredByCompany() {
      if (this.company_type == '0')
        return this.callsFiltered;
      else
        return this.callsFiltered.filter(x => x.COMPANY_TYPE == this.company_type);
    },
    fakeCallsFilteredByCompany() {
      if (this.company_type == '0')
        return this.watch.calls_fake;
      else
        return this.watch.calls_fake.filter(x => x.COMPANY_TYPE == this.company_type);
    },
    data() {
      var data = [];
      var users = this.$store.getters.getAllowedUsers();
      if (this.$store.getters.getBX24User().ADMIN || this.isModerator)
        users = this.options.watch.users;
      users.forEach(user => {
        var calls_real = this.realCallsFilteredByCompany.filter(x => x.PORTAL_USER_ID == user);
        var calls_fake = this.fakeCallsFilteredByCompany.filter(x => x.AUTHOR_ID == user);
        var calls_future = this.watch.calls_future.filter(x => x.AUTHOR_ID == user);
        var quotes = this.watch.quotes.filter(x => x.CREATED_BY_ID == user);
        var userData = {
          user: user,
          calls_real: calls_real.length,
          calls_fake: calls_fake.length,
          calls_future: calls_future.length,
          quotes: quotes.length,
        };
        var rows = [];

        var cols_lists = [calls_real, calls_fake, calls_future, quotes];
        var cols_count = calls_real.length + calls_fake.length + calls_future.length + quotes.length;
        for (let i = 0; i < cols_count; i++) {
          var first_elements = cols_lists.map(x => x[0]);
          var max_index = 0;
          for (let j = 1; j < first_elements.length; j++) {
            if (this.getDate(first_elements[j]) > this.getDate(first_elements[max_index]))
              max_index = j;
          }
          var max_element = cols_lists[max_index].shift();
          var row = [moment(this.getDate(max_element)).format('DD.MM.YYYY HH:mm')];
          for (let k = 0; k < cols_lists.length; k++) {
            if (k == max_index)
              row.push(max_element);
            else
              row.push(undefined);
          }
          rows.push(row);
        }

        userData.rows = rows;
        data.push(userData);
      });
      return data;
    },
  },
  methods: {
    getDate(element) {
      if (element == undefined)
        return "0";
      else if (element.hasOwnProperty('CREATED'))
        return element.CREATED;
      else if (element.hasOwnProperty('START_TIME')) // другая связь (не дата создания, а дата когда был звонок)
       return element.START_TIME;
      else if (element.hasOwnProperty('CALL_START_DATE'))
        return element.CALL_START_DATE;
      else if (element.hasOwnProperty('DATE_CREATE'))
        return element.DATE_CREATE;
    },
    updateData() {
      this.updating = true;
      getCalls(this.date, this.date, this.call_duration_local).then(calls_real => {
        calls_real = calls_real.filter(call => !this.options.watch.phones_exclude.includes(call.PHONE_NUMBER.slice(-10)))

        calls_real.forEach(call => call.COMMENT_LOADED = false)

        getCallsFake(this.date, this.date).then(calls_fake => {
          getCallsFuture(this.date, this.date).then(calls_future => {
            var ids = [];
            var contacts_ids = [];
            calls_real.forEach(call => {
              if (call.CRM_ENTITY_TYPE == 'COMPANY')
                ids.push(call.CRM_ENTITY_ID);
              else if (call.CRM_ENTITY_TYPE == 'CONTACT')
                contacts_ids.push(call.CRM_ENTITY_ID);
            })
            calls_fake.forEach(call => {
              if (call.OWNER_TYPE_ID == '4')
                ids.push(call.OWNER_ID);
              else if (call.OWNER_TYPE_ID == '3')
                contacts_ids.push(call.OWNER_ID);
            })
            getContactCompanyList(contacts_ids).then(contact_companies => {
              this.contacts_ids = contacts_ids;
              this.contact_companies = contact_companies;
            })
            getCompanies(ids).then(companies => {
              calls_real.forEach(call => {
                call.COMPANY_TYPE = '';
                var company = companies.filter(c => c.ID == call.CRM_ENTITY_ID);
                if (company.length > 0)
                  call.COMPANY_TYPE = this.companyType(company[0].COMPANY_TYPE);
              })
              calls_fake.forEach(call => {
                call.COMPANY_TYPE = '';
                var company = companies.filter(c => c.ID == call.OWNER_ID);
                if (company.length > 0)
                  call.COMPANY_TYPE = this.companyType(company[0].COMPANY_TYPE);
              })
              getQuotes(this.date, this.date).then(quotes => {
                this.updating = false;
                this.$store.dispatch('setWatch', {
                  calls_real: calls_real,
                  companies: companies,
                  calls_fake: calls_fake,
                  calls_future: calls_future,
                  quotes: quotes,
                  loaded: true,
                  date: this.date,
                  call_type: this.call_type,
                  call_duration_local: this.call_duration_local,
                })
              });
            });
          });
        });
      });
    },
    updateWatch() {
      this.$store.dispatch('setWatch', {
        calls_real: this.watch.calls_real,
        calls_fake: this.watch.calls_fake,
        calls_future: this.watch.calls_future,
        quotes: this.watch.quotes,
        loaded: true,
        date: this.date,
        call_type: this.call_type,
        call_duration_local: this.call_duration_local,
      })
    },
    selectUsers() {
      window.BX24.selectUsers(this.updateUsers);
    },
    selectModerators() {
      window.BX24.selectUsers(this.updateModerators);
    },
    onUserRowClick(user) {
      this.watch.calls_real.forEach(call => {
        if (call.COMMENT_LOADED == false) {
          if (call.PORTAL_USER_ID == user.user) {
            call.COMMENT_LOADED = true;
            getCallNote(call).then(result => {
              call.COMMENT = result.answer.result.text;
            })
          }
        }
      })
    },
    updateUsers(selectedUsers) {
      var options = this.options;
      options.watch.users = Array.from(new Set(options.watch.users.concat(selectedUsers.map(x => x.id))));
      this.$store.dispatch('setOptions', options);
    },
    updateModerators(selectedUsers) {
      var options = this.options;
      options.watch.moderators = Array.from(new Set(options.watch.moderators.concat(selectedUsers.map(x => x.id))));
      this.$store.dispatch('setOptions', options);
    },
    updateCallDuration() {
      var options = this.options;
      options.watch.call_duration_local = this.call_duration_local;
      this.$store.dispatch('setOptions', options);
      this.call_duration_local = this.call_duration;
    },
    removeUser(userId) {
      var options = this.options;
      options.watch.users.splice(options.watch.users.indexOf(userId), 1);
      this.$store.dispatch('setOptions', options);
    },
    removeModerator(userId) {
      var options = this.options;
      options.watch.moderators.splice(options.watch.moderators.indexOf(userId), 1);
      this.$store.dispatch('setOptions', options);
    },
    addPhones() {
      var phones = this.exclude_phone.replaceAll(' ', '').split(',')
      var options = this.options;
      options.watch.phones_exclude = Array.from(new Set(options.watch.phones_exclude.concat(phones)));
      this.$store.dispatch('setOptions', options);
      this.exclude_phone = '';
    },
    removePhone(phone) {
      var options = this.options;
      options.watch.phones_exclude.splice(options.watch.phones_exclude.indexOf(phone), 1);
      this.$store.dispatch('setOptions', options);
    },
    callsCount(call_type) {
      switch(call_type) {
        case "1":
            return this.watch.calls_real.filter(x => x.CALL_TYPE == "1").length;
        case "2":
          return this.watch.calls_real.filter(x => x.CALL_TYPE == "2" || x.CALL_TYPE == "3").length;
        default:
          return this.watch.calls_real.length;
      }
    },
    companiesCount(company_type) {
      if (company_type == '0')
        return this.watch.calls_real.length + this.watch.calls_fake.length;
      else
        return this.watch.calls_real.filter(x => x.COMPANY_TYPE == company_type).length + this.watch.calls_fake.filter(x => x.COMPANY_TYPE == company_type).length;
    },
    callDuration(value) {
      var sec = value.CALL_DURATION;
      var result = `${sec}с`;
      var min = Math.floor(sec / 60);
      if (min > 0) {
        sec = sec % 60;
        result = `${min}м`;
        if (sec > 0)
          result = result + ` ${sec}с`;
      }
      return result;
    },
    getAudioUrl(row) {
      getFile(row.RECORD_FILE_ID).then(result => {
        row.RECORD_FILE_ID = result.answer.result.DOWNLOAD_URL
        row.ID = -row.ID
      })
    },
    quote(value) {
      return `https://${this.BX24Domain.domain}/crm/quote/show/${value.ID}/`;
    },
    urlByOwner(type, id) {
      if (type == '1' || type == 'LEAD')
        return `https://${this.BX24Domain.domain}/crm/lead/details/${id}/`;
      else if (type == '2' || type == 'DEAL')
        return `https://${this.BX24Domain.domain}/crm/deal/details/${id}/`;
      else if (type == '3' || type == 'CONTACT')
        return `https://${this.BX24Domain.domain}/crm/contact/details/${id}/`;
      else if (type == '4' || type == 'COMPANY')
        return `https://${this.BX24Domain.domain}/crm/company/details/${id}/`;
      else if (type == '5' || type == 'INVOICE')
        return `https://${this.BX24Domain.domain}/crm/invoice/show/${id}/`;
      else if (type == '7' || type == 'QUOTE')
        return `https://${this.BX24Domain.domain}/crm/quote/show/${id}/`;
      else
        return '';
    },
    nameByOwner(type, COMPANY_TYPE) {
      if (type == '1' || type == 'LEAD')
        return 'лиду';
      else if (type == '2' || type == 'DEAL')
        return 'сделке';
      else if (type == '3' || type == 'CONTACT')
        return 'контакту';
      else if (type == '4' || type == 'COMPANY') {
        var companyType = ''
        if (COMPANY_TYPE.length > 0)
          companyType = ' (' + COMPANY_TYPE + ')'
        return 'компании' + companyType;
      }
      else if (type == '5' || type == 'INVOICE')
        return 'счету';
      else if (type == '7' || type == 'QUOTE')
        return 'предложению';
      else
        return '';
    },
    callType(callType) {
      switch(callType) {
        case "1":
          return "Исходящий";
        case "2":
          return "Входящий";
        case "3":
          return "Входящий с перенаправлением";
      }
    },
    companyType(companyType) {
      switch(companyType) {
        case "COMPETITOR":
          return "Перекуп";
        case "OTHER":
          return "Проектировщик";
        case "1":
          return "Заказчик";
        case "2":
          return "Подрядчик";
        case "3":
          return "Подрядчик проект Цифровая Экономика";
        case "4":
          return "Поставщик";
        default:
          return "";
      }
    },
    companyUrlByContactId(id) {
      var index = this.contacts_ids.indexOf(String(id));
      if (this.contact_companies[index] == undefined)
        return '';
      if (this.contact_companies[index].answer.result.length > 0) {
        var company_id = this.contact_companies[index].answer.result[0].COMPANY_ID;
        return this.urlByOwner('COMPANY', company_id);
      } else {
        return '';
      }
    },
  },
  mounted() {
    if (!this.watch.loaded) {
      this.updateData();
    }
  },
  components: {
    User,
  },
  mixins: [fitSize],
}
</script>
