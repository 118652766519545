<template>
  <!-- add modal -->
  <div class="modal fade" id="editAutoStatWidgetModal" tabindex="-1" role="dialog" aria-labelledby="editAutoStatWidgetModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editAutoStatWidgetModalLabel">Редактировать виджет</h5>
          <button type="button" class="close" ref="autostatwidgeteditclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <label for="name">Название</label>
              <input type="text" class="form-control shadow-none" id="name" placeholder="Название виджета" v-model="autoStatWidgetActive.NAME">
            </div>
            <div class="form-group">
              <label for="statType">Объект статистики</label>
              <select class="form-control shadow-none" id="statType" v-model="autoStatWidgetActive.STAT_TYPE">
                <option value="0">Звонки</option>
                <option value="1">Компании</option>
                <option value="2">Сделки</option>
                <option value="3">Заметки</option>
              </select>
            </div>
            <div class="form-group">
              <label for="type">Тип</label>
              <select class="form-control shadow-none" id="type" v-model="autoStatWidgetActive.TYPE">
                <option value="0">Прямая</option>
                <option value="1">Обратная</option>
              </select>
            </div>
            <div class="form-group">
              <label for="range">Диапазон</label>
              <select class="form-control shadow-none" id="range" v-model="autoStatWidgetActive.RANGE">
                <option value="0">День</option>
                <option value="1">Неделя</option>
              </select>
            </div>
            <div class="form-group">
              <label for="user">Сотрудники</label><br>
              <div class="row">
                <div class="col-12" v-for="user in autoStatWidgetActive.USERS" :key="user">
                  <User class="mb-1 d-inline-block" :user="user"/>
                  <button type="button" class="btn btn-danger align-top ml-2 shadow-none mt-1" aria-label="Close" @click="removeUser(user)">X</button>
                </div>
              </div>
              <button type="button" class="btn btn-info shadow-none mt-1" @click="selectUsers">Выбрать</button>
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="button" class="btn btn-success shadow-none float-right" @click="save">Сохранить</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '../components/User';

export default {
  props: {
    autoStatWidgetActive: Object,
  },
  data() {
    return {
      error: '',
    }
  },
  computed: {
    autostatwidgets() {
      return this.$store.getters.getUserOptions().dashboard.autostatwidgets;
    },
    users() {
      return this.$store.getters.getBX24Users();
    },
  },
  methods: {
    selectUsers() {
      window.BX24.selectUsers(this.updateUsers);
    },
    updateUsers(selectedUsers) {
      this.autoStatWidgetActive.USERS = [...new Set(this.autoStatWidgetActive.USERS.concat(selectedUsers.map(x => x.id)))];
    },
    removeUser(userId) {
      this.autoStatWidgetActive.USERS.splice(this.autoStatWidgetActive.USERS.indexOf(userId), 1);
    },
    save() {
      this.error = '';
      if (this.autoStatWidgetActive.NAME.trim().length == 0) {
        this.error = 'введите название'
      } else if (this.autoStatWidgetActive.USERS.length == 0) {
        this.error = 'выберите одного или больше сотрудников'
      } else {
        var data = {
          NAME: this.autoStatWidgetActive.NAME,
          STAT_TYPE: this.autoStatWidgetActive.STAT_TYPE,
          TYPE: this.autoStatWidgetActive.TYPE,
          RANGE: this.autoStatWidgetActive.RANGE,
          USERS: this.autoStatWidgetActive.USERS,
        }
        this.$emit('autoStatWidgetSaved', data);
        this.closeModal();
      }
    },
    closeModal() {
      this.$refs.autostatwidgeteditclose.click();
      this.error = '';
    },
  },
  components: {
    User,
  },
}
</script>
