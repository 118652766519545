<template>
  <div>
    <h2 class="pagetitle">
      <span class="mr-3">Инструкции к сделкам</span>
      <button type="button" class="btn btn-outline-secondary shadow-none float-right" :class="{ active: options.dealinstructions.on }" @click="options.dealinstructions.on = !options.dealinstructions.on; updateOptions()">
        <span v-if="options.dealinstructions.on">Выключить модуль</span>
        <span v-if="!options.dealinstructions.on">Включить модуль</span>
      </button>
      <div class="d-inline-block align-middle float-right mr-2">
        <div class="input-group">
          <select class="form-control custom-select shadow-none float-right cursor-pointer" id="weekStart">
            <option :value="index" v-for="(category, index) in categories" :key="'category_' + category.ID" data-toggle="tab" :href="'#nav-' + category.ID" role="tab" @change="setActive(stage)">{{ category.NAME }}</option>
          </select>
        </div>
      </div>
    </h2>

    <div class="row mx-0 my-3">
      <div class="tab-content col-12 p-0" id="nav-tabContent">
        <div class="tab-pane" :class="{ active : index == 0 }" v-for="(category, index) in categories" :key="'categorytab_' + category.ID" :id="'nav-' + category.ID" role="tabpanel">
          <ul class="nav nav-pills">
            <li class="nav-item mt-2 mr-2" v-for="stage in category.STAGES" :key="'stage_' + stage.STATUS_ID">
              <a class="nav-stage px-3" :class="{ active : stage == stageActive }" href="#" @click="setActive(stage)">{{ stage.NAME }}</a>
            </li>
          </ul>
        </div>
      </div>
      <br>
      <VueEditor class="my-2 mb-4 w-100" rows=30 v-model="text"></VueEditor>
      <button class="btn btn-success mt-5 shadow-none" :disabled="stageActive.STATUS_ID == ''" @click="save">Сохранить</button>
      <div v-show="saved" class="alert alert-success text-left w-100 mt-2" role="alert">Сохранено</div>
    </div>
  </div>
</template>

<script>
import { fitSize } from '../mixins/fitSize';
import { default as DEALINSTRUCTION } from '../models/dealinstruction';
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      instructions: this.$store.getters.getDealInstructions().instructions,
      categories: this.$store.getters.getDealInstructions().categories,
      updating: false,
      saved: false,
      stageActive: {
        NAME: '',
        SORT: '',
        STATUS_ID: '',
      },
      text: '',
    }
  },
  computed: {
    dealinstructions() {
      return this.$store.getters.getDealInstructions();
    },
    options() {
      return this.$store.getters.getOptions();
    },
    categoryActive() {
      for (let i = 0; i < this.categories.length; i++)
        for (let j = 0; j < this.categories[i].STAGES.length; j++)
          if (this.categories[i].STAGES[j] == this.stageActive)
            return this.categories[i];
      return undefined;
    },
    instructionActive() {
      if (this.stageActive)
        for (let i = 0; i < this.instructions.length; i++) {
          if (this.instructions[i].PROPERTY_VALUES.CATEGORY_ID == this.categoryActive.ID && this.instructions[i].PROPERTY_VALUES.STATUS_ID == this.stageActive.STATUS_ID)
            return this.instructions[i];
        }
      return undefined;
    },
  },
  methods: {
    updateData() {
      this.updating = true;
      var categories = [];

      var c = new Promise((resolve) => {
        window.BX24.callBatch({
          default: ['crm.dealcategory.default.get'],
          dealcategories: ['crm.dealcategory.list'],
        }, function(result) {
          categories = [result.default.data()].concat(result.dealcategories.data());
          var batch = [];
          categories.forEach(category => {
            batch.push(['crm.dealcategory.stage.list', { id: category.ID }])
          });
          window.BX24.callBatch(batch, function(result) {
            for (let i = 0; i < categories.length; i++)
              categories[i].STAGES = result[i].data();
            resolve(categories);
          });
        });
      });
      c.then(categories => {
        DEALINSTRUCTION.get().then(instructions => {
          this.updating = false;
          this.categories = categories;
          this.instructions = instructions;
          this.$store.dispatch('setDealInstructions', {
            categories: categories,
            instructions: instructions,
            loaded: true,
          });
        });
      })
    },
    setActive(stage) {
      this.stageActive = stage;
      this.text = this.instructionActive ? this.instructionActive.PROPERTY_VALUES.TEXT : '';
    },
    save() {
      this.saved = false;
      var props = {
        CATEGORY_ID: this.categoryActive.ID,
        STATUS_ID: this.stageActive.STATUS_ID,
        TEXT: this.text,
      }
      if (this.instructionActive) {
        DEALINSTRUCTION.update(this.instructionActive.ID, props).then(() => {
          this.instructionActive.PROPERTY_VALUES = props;
          this.saved = true;
        });
      } else {
        DEALINSTRUCTION.create(props).then(result => {
          this.instructions.push({
            ID: result.answer.result,
            PROPERTY_VALUES: props,
          });
          this.saved = true;
        });
      }
    },
    updateOptions() {
      var c = new Promise((resolve, reject) => {
        window.BX24.callMethod(this.options.dealinstructions.on ? 'placement.bind' : 'placement.unbind', {
          PLACEMENT: 'CRM_DEAL_DETAIL_TAB',
          HANDLER: 'https://orgblocks.co/app/?PAGE=CRM_DEAL_DETAIL_TAB',
          TITLE: 'Инструкции',
        }, function(result) {
          if (result.answer.error)
            reject(result.answer.error);
          else
            resolve(result);
        });
      });
      c.then(() => {
        var options = this.options;
        options.dealinstructions.on = this.options.dealinstructions.on;
        this.$store.dispatch('setOptions', options);
      }).catch(() => this.options.dealinstructions.on = !this.options.dealinstructions.on);
    },
  },
  mounted() {
    if (!this.dealinstructions.loaded) {
      this.updateData();
    }
  },
  components: {
    VueEditor,
  },
  mixins: [fitSize],
}
</script>
