<template>
  <div>
    <h2 class="pagetitle">
      <span class="mr-3">Настройки приложения</span>
    </h2>
    <form>
      <div class="row m-0">
        <div class="form-group col-6 pl-0 mb-1">
          <label for="weekStart">Начало недели</label>
          <select class="form-control custom-select shadow-none cursor-pointer" id="weekStart" v-model="weekStart">
            <option value="1">понедельник</option>
            <option value="2">вторник</option>
            <option value="3">среда</option>
            <option value="4">четверг</option>
            <option value="5">пятница</option>
            <option value="6">суббота</option>
            <option value="0">воскресенье</option>
          </select>
        </div>
        <div class="form-group col-6 pr-0 mb-1">
          <label for="weekends">Выходные</label><br>
          <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" type="checkbox" id="weekends1" value="1" v-model="weekends">
            <label class="form-check-label cursor-pointer" for="weekends1">пн</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" type="checkbox" id="weekends2" value="2" v-model="weekends">
            <label class="form-check-label cursor-pointer" for="weekends2">вт</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" type="checkbox" id="weekends3" value="3" v-model="weekends">
            <label class="form-check-label cursor-pointer" for="weekends3">ср</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" type="checkbox" id="weekends4" value="4" v-model="weekends">
            <label class="form-check-label cursor-pointer" for="weekends4">чт</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" type="checkbox" id="weekends5" value="5" v-model="weekends">
            <label class="form-check-label cursor-pointer" for="weekends5">пт</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" type="checkbox" id="weekends6" value="6" v-model="weekends">
            <label class="form-check-label cursor-pointer" for="weekends6">сб</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" type="checkbox" id="weekends7" value="0" v-model="weekends">
            <label class="form-check-label cursor-pointer" for="weekends7">вс</label>
          </div>
        </div>
      </div>
    </form>
    <button class="btn btn-success shadow-none" @click="saveOptions">Сохранить</button>
    <div v-if="saved" class="alert alert-success mt-2" role="alert">{{ saved }}</div>
    
    <h2 class="uppercase mt-4">Таблицы</h2>
    <div class="card-columns">
      <Table v-for="table in tables" :key="table.NAME" :table="table" :tables="tablesCreated" @propsAdded="propsAdded" @tableCreated="tableCreated"/>
    </div>
    
    <button v-if="!(installed || installing)" class="btn btn-primary" @click="install">Создать таблицы</button>
    <div v-if="installing" class="spinner-border text-primary" role="status">
      <span class="sr-only">создание таблиц...</span>
    </div>
    <div v-if="ok" class="alert alert-success" role="alert">{{ ok }}</div>
  </div>
</template>

<script>
import { getTables, getTablesProperties } from '../models/api';
import { default as STAT } from '../models/stat.js';
import { default as STATVALUE } from '../models/statvalue.js';
import { default as STATTEMPLATE } from '../models/stattemplate.js';
import { default as POST } from '../models/post.js';
import { default as BATTLEPLAN } from '../models/battleplan.js';
import { default as DEALINSTRUCTION } from '../models/dealinstruction.js';
import Table from '../components/Table';
import { fitSize } from '../mixins/fitSize';
import { saveBitrixOptions } from '../server/api';
var moment = require('moment');

export default {
  data() {
    return {
      weekStart: this.$store.getters.getOptions().weekStart,
      weekends: this.$store.getters.getOptions().weekends,
      isNotify: this.$store.getters.getOptions().isNotify,
      notificationTime: this.$store.getters.getOptions().notificationTime,
      watch: this.$store.getters.getOptions().watch,
      tables: [STAT, STATVALUE, POST, STATTEMPLATE, BATTLEPLAN, DEALINSTRUCTION],
      tablesCreated: this.$store.getters.getTablesCreated(),
      ok: '',
      saved: '',
      installed: true,
      installing: false,
    }
  },
  computed: {
    BX24Domain() {
      return this.$store.getters.getBX24Domain();
    },
  },
  methods: {
    saveOptions() {
      this.saved = '';
      var options = {
        weekStart: this.weekStart,
        weekends: this.weekends,
        isNotify: this.isNotify,
        notificationTime: this.notificationTime,
        watch: this.watch,
      }
      this.$store.dispatch('setOptions', options).then(() => this.saved = 'сохранено');

      var now = moment();
      now.hour(this.notificationTime.split(':')[0]);
      now.minute(this.notificationTime.split(':')[1]);
      options.notificationTime = now.format();
      options.bitrix = this.BX24Domain.member_id;
      saveBitrixOptions(options);
    },
    install() {
      this.installing = true;
      this.createTables();
    },
    tableCreated(table) {
      this.tablesCreated.push(table);
      this.$store.dispatch('setTablesCreated', this.tablesCreated);
    },
    propsAdded(tableName, props) {
      for (let i = 0; i < this.tablesCreated.length; i++) {
        if(tableName == this.tablesCreated[i].NAME) {
          this.tablesCreated[i].PROPERTIES = props;
        } 
      }
      this.$store.dispatch('setTablesCreated', this.tablesCreated);
    },
    createTables() {
      STAT.prepare()
        .then(() => {
          STATVALUE.prepare()
            .then(() => {
              POST.prepare()
                .then(() => {
                  this.finishInstall();
                })
                .catch((error) => window.console.warn('create tables: ' + error.error));
            })
            .catch((error) => window.console.warn('create tables: ' + error.error));
        })
        .catch((error) => window.console.warn('create tables: ' + error.error));
    },
    finishInstall() {
      this.ok = 'создание завершено';
      this.installing = false;
      getTables().then((data) => {
        var tablesCreated = data;
        getTablesProperties(tablesCreated)
          .then((data) => {
            tablesCreated.forEach(table => {
              table.PROPERTIES = data[table.NAME].answer.result;
            });
            this.tablesCreated = tablesCreated;
            this.$store.dispatch('setTablesCreated', tablesCreated);
            if (this.tablesCreated.length > 0)
              this.installed = true;
          })
          .catch((error) => window.console.warn('get table props: ' + error.error));
      });
    },
  },
  created() {
    if (this.tablesCreated.length == 0) {
      getTables().then((data) => {
        var tablesCreated = data;
        getTablesProperties(tablesCreated)
          .then((data) => {
            tablesCreated.forEach(table => {
              table.PROPERTIES = data[table.NAME].answer.result;
            });
            this.tablesCreated = tablesCreated;
            this.$store.dispatch('setTablesCreated', tablesCreated);
            if (this.tablesCreated.length == 0)
              this.installed = false;
          })
          .catch((error) => window.console.warn('get table props: ' + error.error));
      });
    }
  },
  components: {
    Table,
  },
  mixins: [fitSize],
}
</script>
