<template>
  <div class="card mr-2 mb-2" style="width: 300px;">
    <div class="card-header">
      {{ table.model.NAME }}
    </div>
    <div class="card-body p-0">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" :class="{'list-group-item-success' : prop.created}" v-for="prop in props" :key="prop.PROPERTY">
          {{ prop.PROPERTY }}<div class="float-right">{{ prop.TYPE }}</div>
        </li>
      </ul>
    </div>
    <div class="card-footer text-right">
      <div v-if="!tableCreated && showCreate" class="btn btn-primary cursor-pointer" @click="prepareTable">Создать</div>
      <div v-if="tableCreated && !allProps" class="btn btn-primary cursor-pointer" @click="addProps">Добавить поля</div>
    </div>
  </div>
</template>

<script>
import { fitSize } from '../mixins/fitSize';

export default {
  props: {
    table: Object,
    tables: Array,
  },
  data() {
    return {
      showCreate: true,
    }
  },
  computed: {
    tableCreated() {
      var created = false;
      this.tables.forEach(t => {
        if (t.NAME == this.table.model.NAME)
          created = true;
      });
      return created;
    },
    propsCreated() {
      var props = [];
      this.tables.forEach(t => {
        if (t.NAME == this.table.model.NAME)
          props = t.PROPERTIES;
      });
      return props;
    },
    props() {
      var props = [];
      this.table.model.PROPERTIES.forEach(p => {
        p.created = false;
        this.propsCreated.forEach(pC => {
          if (p.PROPERTY == pC.PROPERTY)
            p.created = true;
        });
        props.push(p);
      });
      return props;
    },
    allProps() {
      var result = true;
      this.table.model.PROPERTIES.forEach(p => {
        p.created = false;
        this.propsCreated.forEach(pC => {
          if (p.PROPERTY == pC.PROPERTY)
            p.created = true;
        });
        if (!p.created)
          result = false;
      });
      return result;
    },
  },
  methods: {
    prepareTable() {
      this.table.prepare()
        .then(() => {
          this.$emit('tableCreated', this.table.model);
          // getTableProperties(this.table.model.NAME)
          //   .then((data) => {
          //     table.props = data;
          //     this.$emit('tableCreated', table);
          //     this.showCreate = false;
          //   })
          //   .catch((error) => window.console.warn('get table props: ' + error.error));
        })
        .catch((error) => window.console.warn('create table: ' + error.error));
    },
    addProps() {
      var props = [];
      this.table.model.PROPERTIES.forEach(p => {
        p.created = false;
        this.propsCreated.forEach(pC => {
          if (p.PROPERTY == pC.PROPERTY)
            p.created = true;
        });
        if (!p.created)
          props.push(p);
      });
      this.table.addProps(props)
        .then(() => {
          this.$emit('propsAdded', this.table.model.NAME, this.table.model.PROPERTIES);
          // getTableProperties(this.table.model.NAME)
          //   .then((data) => {
          //     this.$emit('propsCreated', this.table.model.NAME, data);
          //   })
          //   .catch((error) => window.console.warn('get table props: ' + error.error));
        })
        .catch((error) => window.console.warn('create props: ' + error.error));
    },
  },
  mixins: [fitSize],
}
</script>

<style scoped>
.card {
  display: inline-block;
}
</style>
