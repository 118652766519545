import store from './store';
import { default as POST } from './models/post';
import { default as STATTEMPLATE } from './models/stattemplate';
import { getDepartments } from './models/api';

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    window.BX24.callMethod('user.current', {},
      function(result) {
        if (result.error())
          reject(result.error());
        else
          resolve(result.data());
      },
    );
  });
}

function getAllUsers() {
  return new Promise((resolve, reject) => {
    var data = [];
    window.BX24.callMethod(
      "user.get", { ACTIVE: true },
      function(result) {
        if (result.error())
          reject(result.error());
        else {
          data = data.concat(result.data());
          if(result.more())
            result.next();
          else
            resolve(data);
        }
      },
    );
  });
}

export function init() {
  return new Promise((resolve) => {
    getCurrentUser().then(user => {
      var auth = window.BX24.getAuth();
      user.ADMIN = window.BX24.isAdmin();
      store.dispatch('setBX24Domain', auth).then(() =>
        store.dispatch('setBX24User', user).then(() =>
          store.dispatch('initialiseStore').then(() => {
            getAllUsers().then(users => {
              store.dispatch('setBX24Users', users);
              // not waiting posts
              getDepartments().then(departments => store.dispatch('setDepartments', departments));

              POST.get().then(posts => {
                var postStatTemplatesPromises = [];
                posts.forEach(post => {
                  postStatTemplatesPromises.push(new Promise((resolve) => {
                    STATTEMPLATE.get({PROPERTY_POST_ID: post.ID})
                      .then(statTemplates => {
                        post.statTemplates = statTemplates.length > 0 ? statTemplates : [];
                        resolve();
                      });
                  }));
                });
                Promise.all(postStatTemplatesPromises)
                  .then(() => {
                    store.dispatch('setPosts', posts);
                  })
                // not waiting stattemplates
                resolve();
              }).catch(() => resolve());
            });
          })
        )
      );
    });
  });
}