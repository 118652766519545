import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Stats from '../views/Stats.vue';
import Posts from '../views/Posts.vue';
import Settings from '../views/Settings.vue';
import Watch from '../views/Watch.vue';
import BattlePlans from '../views/BattlePlans.vue';
import DealInstructions from '../views/DealInstructions.vue';
import ErrorV from '../views/Error.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/stats',
    name: 'stats',
    component: Stats,
  },
  {
    path: '/posts',
    name: 'posts',
    component: Posts,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/watch',
    name: 'watch',
    component: Watch,
  },
  {
    path: '/battleplans',
    name: 'battleplans',
    component: BattlePlans,
  },
  {
    path: '/dealinstructions',
    name: 'dealinstructions',
    component: DealInstructions,
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorV,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router;
