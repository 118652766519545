<template>
  <div>
    <h2 class="pagetitle">
      <span class="mr-3">Дашборд</span>
      <button type="button" class="btn btn-info shadow-none mb-1" @click="updateData()" :disabled="updating" title="Обновить данные">
        <i v-if="!updating" class="fa fa-refresh"></i>
        <i v-if="updating" class="fa fa-refresh fa-spin"></i>
      </button>

      <button type="button" class="btn btn-outline-secondary shadow-none float-right ml-2" data-toggle="button" :aria-pressed="showStatRange" @click="showStatRange = !showStatRange" title="Показать настройки">
        <i class="fa fa-cog"></i>
      </button>
      <div class="d-inline-block align-middle float-right ml-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <button type="button" class="btn btn-outline-secondary shadow-none" data-toggle="modal" data-target="#dashboardModalAdd">
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <select class="form-control custom-select shadow-none cursor-pointer" id="dashboardCurrent" v-model="dashboardCurrent">
            <option v-for="(dashboard, index) in dashboards" :key="index" :value="index">{{ dashboard.name }}</option>
          </select>
          <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary shadow-none" data-toggle="modal" data-target="#dashboardModalEdit">
              <i class="fa fa-edit"></i>
            </button>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-info shadow-none float-right ml-2" data-toggle="modal" data-target="#autoStatWidgetModalAdd" title="Добавить виджет из данных Битрикс24">
        <i class="fa fa-plus"></i> Добавить из Битрикс24
      </button>
      <button type="button" class="btn btn-info shadow-none float-right ml-2" data-toggle="modal" data-target="#statWidgetModalAdd" title="Добавить виджет из ручных статистик">
        <i class="fa fa-plus"></i> Добавить из статистик
      </button>
    </h2>

    <form v-show="showStatRange">
      <div class="row mr-0 ml-0">
        <div class="form-group pl-0 col">
          <label for="statsWidthRange">ширина</label>
          <input type="range" min="400" max="2000" step="10" class="form-control-range" id="statsWidthRange" v-model="statsWidth" @change="updateDashboard">
        </div>
        <div class="form-group pr-0 col">
          <label for="statsHeightRange">высота</label>
          <input type="range" min="200" max="1000" step="10" class="form-control-range" id="statsHeightRange" v-model="statsHeight" @change="updateDashboard">
        </div>
      </div>
      <div class="row mr-0 ml-0">
        <div class="form-group form-inline px-0 col">
          <input type="date" class="form-control shadow-none mr-2" id="from" v-model="from" required>
          <label for="to" class="mr-2">-</label>
          <input type="date" class="form-control shadow-none mr-2" id="to" v-model="to" required>
          <button type="button" class="btn btn-outline-secondary shadow-none mr-2" :class="{ active: showWeekends }" @click="$store.commit('setDashboardShowWeekends', !showWeekends); updateDashboard()">
            показать выходные
          </button>
          <button class="btn btn-success shadow-none mr-2" @click.prevent="filterModified = true; updateData()" :disabled="updating" title="Применить фильтр по датам">
            <div v-if="!updating">Применить </div>
            <div v-if="updating">Обновление</div>
          </button>
        </div>
      </div>
    </form>

    <div class="row m-0">
      <StatWidget ref="statwidgets" v-for="stat in statwidgets" :key="stat.ID" :stat="stat" @statWidgetRemoved="statWidgetRemoved" @statWidgetSelected="statWidgetSelected" @statWidgetDateInfoSelected="statWidgetDateInfoSelected"/>
      <AutoStatWidget ref="autostatwidgets" v-for="stat in autostatwidgets" :key="stat.ID" :stat="stat" :calls="calls" :companies="companies" :deals="deals" :notes="notes" @autoStatWidgetRemoved="autoStatWidgetRemoved" @autoStatWidgetSelected="autoStatWidgetSelected" @autoStatWidgetDateInfoSelected="autoStatWidgetDateInfoSelected"/>
    </div>

    <StatWidgetModalAdd @statWidgetAdded="statWidgetAdded"/>
    <StatWidgetModalEdit :statWidgetActive="statWidgetActive" @statWidgetSaved="statWidgetSaved"/>
    <StatWidgetDateInfo :statWidgetActive="statWidgetActive" :dateInfo="dateInfo"/>
    <AutoStatWidgetModalAdd @autoStatWidgetAdded="autoStatWidgetAdded"/>
    <AutoStatWidgetModalEdit :autoStatWidgetActive="autoStatWidgetActive" @autoStatWidgetSaved="autoStatWidgetSaved"/>
    <AutoStatWidgetDateInfo :autoStatWidgetActive="autoStatWidgetActive" :dateInfo="dateInfo"/>
    <DashboardModalEdit @updateDashboard="updateDashboard"/>
    <DashboardModalAdd @updateDashboard="updateDashboard"/>
    <button ref="showautostatwidgetdateinfo" type="button" class="invisible" data-toggle="modal" data-target="#dateInfoModal"></button>
    <button ref="showstatwidgetdateinfo" type="button" class="invisible" data-toggle="modal" data-target="#dateStatInfoModal"></button>
  </div>
</template>

<script>
import { getCalls, getCompanies, getDeals, getNotes } from '../models/api';
import StatWidget from '../components/StatWidget';
import StatWidgetModalAdd from '../components/StatWidgetModalAdd';
import StatWidgetModalEdit from '../components/StatWidgetModalEdit';
import StatWidgetDateInfo from '../components/StatWidgetDateInfo';
import AutoStatWidget from '../components/AutoStatWidget';
import AutoStatWidgetModalAdd from '../components/AutoStatWidgetModalAdd';
import AutoStatWidgetModalEdit from '../components/AutoStatWidgetModalEdit';
import AutoStatWidgetDateInfo from '../components/AutoStatWidgetDateInfo';
import DashboardModalEdit from '../components/DashboardModalEdit';
import DashboardModalAdd from '../components/DashboardModalAdd';
import { fitSize } from '../mixins/fitSize';
import deepcopy from '../helpers/deepcopy';
var moment = require('moment');

export default {
  data() {
    return {
      stats: this.$store.getters.getStats(),
      dashboards: this.$store.getters.getUserOptions().dashboards,
      showStatRange: false,
      from: this.$store.getters.getDashboard().from,
      to: this.$store.getters.getDashboard().to,
      calls: this.$store.getters.getDashboard().calls,
      companies: this.$store.getters.getDashboard().companies,
      deals: this.$store.getters.getDashboard().deals,
      notes: this.$store.getters.getDashboard().notes,
      statWidget: {},
      statWidgetActive: {},
      autoStatWidget: {},
      autoStatWidgetActive: {},
      dateInfo: [],
      filterModified: this.$store.getters.getDashboard().filterModified,
      updating: false,
    }
  },
  watch: {
    statsWidth() {
      this.$store.dispatch('setDashboardStatsWidth', this.statsWidth);
    },
    statsHeight() {
      this.$store.dispatch('setDashboardStatsHeight', this.statsHeight);
    },
    showWeekends() {
      this.$store.dispatch('setDashboardShowWeekends', this.showWeekends);
    },
  },
  computed: {
    user() {
      return this.$store.getters.getBX24User();
    },
    weekStart() {
      return this.$store.getters.getOptions().weekStart;
    },
    dashboardCurrent: {
      get() {
        return this.$store.getters.getDashboard().dashboardCurrent;
      },
      set(value) {
        this.$store.dispatch('setDashboardCurrent', Number.parseInt(value)).then(() => {
          if (this.statwidgets.length > 0)
            this.$refs.statwidgets.forEach(stat => stat.updateData());
          if (this.autostatwidgets.length > 0)
            this.$refs.autostatwidgets.forEach(stat => stat.updateData());
        });
      },
    },
    statwidgets() {
      return this.$store.getters.getDashboardCurrent().statwidgets;
    },
    autostatwidgets() {
      return this.$store.getters.getDashboardCurrent().autostatwidgets;
    },
    statsWidth: {
      get() {
        return this.$store.getters.getDashboardCurrent().statsWidth;
      },
      set(value) {
        this.$store.commit('setDashboardStatsWidth', value);
      },
    },
    statsHeight: {
      get() {
        return this.$store.getters.getDashboardCurrent().statsHeight;
      },
      set(value) {
        this.$store.commit('setDashboardStatsHeight', value);
      },
    },
    showWeekends() {
      return this.$store.getters.getDashboardCurrent().showWeekends;
    },
  },
  methods: {
    statWidgetAdded(stat) {
      this.statwidgets.push(stat);
      this.updateDashboard();
    },
    statWidgetRemoved(stat) {
      this.statwidgets.splice(this.statwidgets.indexOf(stat), 1);
      this.updateDashboard();
    },
    statWidgetSelected(stat) {
      this.statWidget = stat;
      this.statWidgetActive = deepcopy(stat);
    },
    statWidgetSaved(stat) {
      this.statWidget.NAME = stat.NAME,
      this.statWidget.TYPE = stat.TYPE,
      this.statWidget.RANGE = stat.RANGE,
      this.statWidget.POST_ID = stat.POST_ID,
      this.statWidget.STATTEMPLATE_ID = stat.STATTEMPLATE_ID,
      this.statWidget.STATS = stat.STATS,
      this.updateDashboard();
      this.$refs.statwidgets.forEach(stat => stat.updateData());
    },
    statWidgetDateInfoSelected(stat, datasets, index) {
      this.statWidget = stat;
      this.statWidgetActive = deepcopy(stat);
      this.dateInfo = [];
      datasets.forEach(dataset => {
        this.dateInfo.push({
          user: dataset.label,
          data: dataset.data[index],
          day: dataset.days[index],
          values: dataset.values[index],
        })
      })
      if (this.dateInfo.length > 0)
        this.$refs.showstatwidgetdateinfo.click();
    },
    updateData() {
      this.updating = true;
      if (this.statwidgets.length > 0)
        this.$refs.statwidgets.forEach(stat => stat.updateData(!this.filterModified));
      
      var from = this.from;
      if (!this.filterModified)
        from = moment(this.to).subtract(9, 'w').format('YYYY-MM-DD');
      getCalls(from, this.to).then(calls => {
        this.calls = calls;
        getCompanies(from, this.to).then(companies => {
          this.companies = companies;
          getDeals(from, this.to).then(deals => {
            this.deals = deals;
            getNotes(from, this.to).then(notes => {
              this.notes = notes;
              this.updating = false;
              this.$store.dispatch('setDashboard', {
                calls: this.calls,
                companies: this.companies,
                deals: this.deals,
                notes: this.notes,
                loaded: true,
                from: this.from,
                to: this.to,
                filterModified: this.filterModified,
                dashboardCurrent: 0,
              }).then(() => {
                if (this.statwidgets.length > 0)
                  this.$refs.statwidgets.forEach(stat => stat.updateData());
                if (this.autostatwidgets.length > 0)
                  this.$refs.autostatwidgets.forEach(stat => stat.updateData());
              })
            });
          });
        });
      });
    },
    autoStatWidgetAdded(data) {
      this.autostatwidgets.push(data);
      this.updateDashboard();
    },
    autoStatWidgetRemoved(stat) {
      this.autostatwidgets.splice(this.autostatwidgets.indexOf(stat), 1);
      this.updateDashboard();
    },
    autoStatWidgetSelected(stat) {
      this.autoStatWidget = stat;
      this.autoStatWidgetActive = deepcopy(stat);
    },
    autoStatWidgetSaved(stat) {
      this.autoStatWidget.NAME = stat.NAME,
      this.autoStatWidget.STAT_TYPE = stat.STAT_TYPE,
      this.autoStatWidget.TYPE = stat.TYPE,
      this.autoStatWidget.RANGE = stat.RANGE,
      this.autoStatWidget.USERS = stat.USERS,
      this.updateDashboard();
      this.$refs.autostatwidgets.forEach(stat => stat.updateData());
    },
    autoStatWidgetDateInfoSelected(stat, datasets, index) {
      this.autoStatWidget = stat;
      this.autoStatWidgetActive = deepcopy(stat);
      this.dateInfo = [];
      datasets.forEach(dataset => {
        this.dateInfo.push({
          user: dataset.label,
          data: dataset.data[index],
          day: dataset.days[index],
          values: dataset.values[index],
        })
      })
      if (this.dateInfo.length > 0)
        this.$refs.showautostatwidgetdateinfo.click();
    },
    updateDashboard() {
      var data = {
        dashboards: this.$store.getters.getUserOptions().dashboards,
      };
      this.$store.dispatch('setUserDashboardOptions', data);
    }
  },
  mounted() {
    if (!this.$store.getters.getDashboard().loaded) {
      if (moment().day() >= this.weekStart)
        this.from = moment().day(this.weekStart);
      else
        this.from = moment().day(this.weekStart - 7);
      this.to = moment(this.from).add(6, 'd').format('YYYY-MM-DD');
      this.from = this.from.format('YYYY-MM-DD');
      this.updateData();
    }
  },
  components: {
    StatWidget,
    StatWidgetModalAdd,
    StatWidgetModalEdit,
    StatWidgetDateInfo,
    AutoStatWidget,
    AutoStatWidgetModalAdd,
    AutoStatWidgetModalEdit,
    AutoStatWidgetDateInfo,
    DashboardModalEdit,
    DashboardModalAdd,
  },
  mixins: [fitSize],
}
</script>
