<template>
  <div>
    <h2 class="pagetitle">
      <span class="mr-3">Статистики</span>
      <button type="button" class="btn btn-info shadow-none mr-2 mb-1" @click="updateData()" :disabled="updating" title="Обновить данные">
        <i v-if="!updating" class="fa fa-refresh"></i>
        <i v-if="updating" class="fa fa-refresh fa-spin"></i>
      </button>
      <button type="button" class="btn btn-outline-secondary shadow-none float-right ml-2" data-toggle="button" :aria-pressed="showStatRange" @click="showStatRange = !showStatRange" title="Показать настройки">
        <i class="fa fa-cog"></i>
      </button>
      <button v-if="user.ADMIN" type="button" class="btn btn-info shadow-none float-right ml-2" data-toggle="modal" data-target="#createStatModal" title="Добавить статистику">
        <i class="fa fa-plus"></i> Добавить статистику
      </button>
      <button v-if="user.ADMIN" type="button" class="btn btn-info shadow-none float-right ml-2" data-toggle="modal" data-target="#createFromPostModal" title="Добавить статистику по шаблону">
        <i class="fa fa-plus"></i> Добавить по шаблону
      </button>
    </h2>

    <form v-show="showStatRange">
      <div class="row mr-0 ml-0">
        <div class="form-group pl-0 col">
          <label for="statsWidthRange">ширина</label>
          <input type="range" min="400" max="2000" step="10" class="form-control-range" id="statsWidthRange" v-model="statsWidth" @change="setUserOptions">
        </div>
        <div class="form-group pr-0 col">
          <label for="statsHeightRange">высота</label>
          <input type="range" min="200" max="1000" step="10" class="form-control-range" id="statsHeightRange" v-model="statsHeight"  @change="setUserOptions">
        </div>
      </div>
      <div class="row mr-0 ml-0">
        <div class="form-group form-inline px-0 col">
          <input type="date" class="form-control shadow-none mr-2" id="from" v-model="from" required>
          <label for="to" class="mr-2">-</label>
          <input type="date" class="form-control shadow-none mr-2" id="to" v-model="to" required>
          <button type="button" class="btn btn-outline-secondary shadow-none mr-2" :class="{ active: showWeekends }" @click="showWeekends = !showWeekends; setUserOptions()">
            показать выходные
          </button>
          <button class="btn btn-success shadow-none mr-2" @click.prevent="filterModified = true; updateData()" :disabled="updating" title="Применить фильтр по датам">
            <div v-if="!updating">Применить </div>
            <div v-if="updating">Обновление</div>
          </button>
        </div>
      </div>
    </form>

    <div class="row m-0">
      <Stat ref="stats" v-for="stat in stats" :key="stat.ID" :stat="stat" @statSelected="statSelected" @statValueSelected="statValueSelected" :from="from" :to="to"/>
    </div>

    <FromPostCreate @statsCreated="statsCreated"/>
    <StatModalCreate @statCreated="statCreated"/>
    <StatModalEdit :statActive="statActive" @statSaved="statSaved" @statDeleted="statDeleted"/>
    <StatValueModalEdit :statActive="statActive" :statValueActive="statValueActive" @statValueCreated="statValueCreated" @statValueSaved="statValueSaved" @statValueDeleted="statValueDeleted" :error="statValueError" @statValueErrorUpdate="statValueErrorUpdate"/>
  </div>
</template>

<script>
import { default as STAT } from '../models/stat';
import { default as STATVALUE } from '../models/statvalue';
import Stat from '../components/Stat';
import FromPostCreate from '../components/FromPostCreate';
import StatModalCreate from '../components/StatModalCreate';
import StatModalEdit from '../components/StatModalEdit';
import StatValueModalEdit from '../components/StatValueModalEdit';
import deepcopy from '../helpers/deepcopy';
import { fitSize } from '../mixins/fitSize';
var moment = require('moment');

export default {
  data() {
    return {
      stats: this.$store.getters.getStats().stats,
      statvalues: this.$store.getters.getStats().statvalues,
      from: this.$store.getters.getStats().from,
      to: this.$store.getters.getStats().to,
      filterModified: this.$store.getters.getStats().filterModified,
      stat: {
        PROPERTY_VALUES: {},
      },
      statActive: {
        DATE_CREATE: '',
        CREATED_BY: '',
        TIMESTAMP_X: '',
        MODIFIED_BY: '',
        PROPERTY_VALUES: {},
      },
      statValue: {
        PROPERTY_VALUES: {},
      },
      statValueActive: {
        PROPERTY_VALUES: {},
      },
      statValueError: '',
      statsWidth: this.$store.getters.getUserOptions().stats.statsWidth,
      statsHeight: this.$store.getters.getUserOptions().stats.statsHeight,
      showWeekends: this.$store.getters.getUserOptions().stats.showWeekends,
      showStatRange: false,
      updating: false,
    }
  },
  watch: {
    statsWidth() {
      this.$store.dispatch('setStatsWidth', this.statsWidth);
    },
    statsHeight() {
      this.$store.dispatch('setStatsHeight', this.statsHeight);
    },
    showWeekends() {
      this.$store.dispatch('setShowWeekends', this.showWeekends);
    },
  },
  computed: {
    user() {
      return this.$store.getters.getBX24User();
    },
    weekStart() {
      return this.$store.getters.getOptions().weekStart;
    },
  },
  methods: {
    statCreated(stat) {
      this.stats.push(stat);
    },
    statsCreated(stats) {
      stats.forEach(stat => {
        this.stats.push(stat);
      })
    },
    statSelected(stat) {
      this.stat = stat;
      this.statActive = deepcopy(stat);
    },
    statSaved(stat) {
      STAT.copy(stat, this.stat);
    },
    statDeleted(stat) {
      var index = -1;
      for (var i = 0; i < this.stats.length; i++)
        if (this.stats[i].ID === stat.ID)
          index = i;
      this.stats.splice(index, 1);
    },
    statValueSelected(statValue, stat) {
      this.statActive = deepcopy(stat);
      this.statValue = statValue;
      this.statValueActive = deepcopy(statValue);
      this.statValueErrorUpdate('');
    },
    statValueCreated(statValue) {
      STATVALUE.copy(statValue, this.statValue);
      this.statvalues.push(statValue);
      this.$store.dispatch('setStatValues', this.statvalues);
    },
    statValueSaved(statValue) {
      STATVALUE.copy(statValue, this.statValue);
      for (let i = 0; i < this.statvalues.length; i++) {
        if (this.statvalues[i].PROPERTY_VALUES.DATE == statValue.PROPERTY_VALUES.DATE) {
          this.statvalues[i] = statValue;
          break;
        }
      }
      this.$store.dispatch('setStatValues', this.statvalues);
    },
    statValueDeleted(statValue) {
      STATVALUE.copy(statValue, this.statValue);
      var index = -1;
      for (var i = 0; i < this.statvalues.length; i++)
        if (this.statvalues[i].ID === statValue.ID) {
          index = i;
          break;
        }
      if (index > 0) {
        this.statvalues.splice(index, 1);
        this.$store.dispatch('setStatValues', this.statvalues);
      }
    },
    statValueErrorUpdate(error) {
      this.statValueError = error;
    },
    setUserOptions() {
      var data = {
        stats: {
          statsWidth: this.statsWidth,
          statsHeight: this.statsHeight,
          showWeekends: this.showWeekends,
        },
      };
      this.$store.dispatch('setUserStatsOptions', data);
    },
    updateData() {
      this.updating = true;
      var from = this.from;
      if (!this.filterModified) {
        from = moment(this.to).subtract(9, 'w');
        while (from.day() != this.weekStart)
          from.add(1, 'd');
      }
      from = moment(from).format('YYYYMMDD');
      var to = moment(this.to).format('YYYYMMDD');

      var filter = {PROPERTY_USER_ID: this.$store.getters.getAllowedUsers()};
      if (this.user.ADMIN) filter = {};
      STAT.get(filter)
        .then(stats => {
          STATVALUE.get({PROPERTY_STAT_ID: this.stats.map(x => x.ID), '>=PROPERTY_DATE': from, '<=PROPERTY_DATE': to}).then(statvalues => {
            this.statvalues = statvalues;
            this.stats = stats;
            this.loaded = true;
            this.$store.dispatch('setStats', {
              stats: this.stats,
              statvalues: this.statvalues,
              loaded: true,
              from: this.from,
              to: this.to,
              filterModified: this.filterModified,
            }).then(() => {
              this.updating = false;
              if (this.stats.length > 0)
                this.$refs.stats.forEach(stat => stat.updateData());
            })
          }).catch((error) => window.console.warn('get statvalues: ' + error.error));
        }).catch((error) => window.console.warn('get stats: ' + error.error));
    },
  },
  mounted() {
    if (!this.$store.getters.getStats().loaded) {
      if (moment().day() >= this.weekStart)
        this.from = moment().day(this.weekStart);
      else
        this.from = moment().day(this.weekStart - 7);
      this.to = moment(this.from).add(6, 'd').format('YYYY-MM-DD');
      this.from = this.from.format('YYYY-MM-DD');
      this.updateData();
    }
  },
  components: {
    Stat,
    FromPostCreate,
    StatModalCreate,
    StatModalEdit,
    StatValueModalEdit,
  },
  mixins: [fitSize],
}
</script>
