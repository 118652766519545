<template>
  <div>
    <h2>Ошибка - обратитесь к администратору</h2>
  </div>
</template>

<script>
import { fitSize } from '../mixins/fitSize';

export default {
  mixins: [fitSize],
}
</script>
