<template>
  <div class="card mr-2 mb-2 p-0" :style="{width: width + 40 + 'px'}">
    <div class="card-body">
      <h5 class="card-title">
        <span v-if="post" class="badge badge-primary pb-1">{{ post.PROPERTY_VALUES.NAME }}</span>
        <div class="d-inline align-middle float-right">{{ stat.PROPERTY_VALUES.NAME }}</div>
      </h5>
      <div class="row">
        <div class="col">
          <User v-if="stat.PROPERTY_VALUES.USER_ID" :user="stat.PROPERTY_VALUES.USER_ID"/>
          <span v-if="stat.PROPERTY_VALUES.USER_ID == 'undefined'" class="badge badge-danger">ответственный не назначен</span>
        </div>
        <div class="col">
          <button v-if="user.ADMIN" type="button" class="btn btn-outline-secondary shadow-none btn-lg float-right ml-2 mt-1" data-toggle="modal" data-target="#editStatModal" @click="$emit('statSelected', stat)"><i class="fa fa-pencil"></i></button>
          <div class="float-right text-right mt-1">
            <span class="badge badge-info">{{ strType(stat.PROPERTY_VALUES.TYPE) }}</span><br>
            <span class="badge badge-info">{{ strRange(stat.PROPERTY_VALUES.RANGE) }}</span>
          </div>
        </div>
      </div>
      <div class="stat-wrapper mt-2" :style="{width: width + 'px', height: height + 'px'}">
        <Chart :chartData="data" :options="options" :style="{width: width + 'px', height: height + 'px'}"/>
      </div>
      <button ref="showstatvalue" type="button" class="invisible" data-toggle="modal" data-target="#editStatValueModal"></button>
    </div>
  </div>
</template>

<script>
import Chart from '../components/Chart';
import User from '../components/User';
import { fitSize } from '../mixins/fitSize';
var moment = require('moment');

export default {
  props: {
    stat: Object,
  },
  data() {
    return {
      statvaluesWithBlank: [],
    }
  },
  computed: {
    from() {
      return this.$store.getters.getStats().from;
    },
    to() {
      return this.$store.getters.getStats().to;
    },
    user() {
      return this.$store.getters.getBX24User();
    },
    weekStart() {
      return this.$store.getters.getOptions().weekStart;
    },
    width() {
      return Number.parseInt(this.$store.getters.getUserOptions().stats.statsWidth);
    },
    height() {
      return Number.parseInt(this.$store.getters.getUserOptions().stats.statsHeight);
    },
    weekends() {
      return this.$store.getters.getOptions().weekends;
    },
    showWeekends() {
      return this.$store.getters.getUserOptions().stats.showWeekends;
    },
    posts() {
      return this.$store.getters.getPosts();
    },
    post() {
      var post = null;
      this.posts.forEach(p => {
        if (this.stat.PROPERTY_VALUES.POST_ID == p.ID)
          post = p;
      });
      return post;
    },
    labels() {
      var labels = [];
      this.statvaluesFiltered.forEach(statvalue => {
        labels.push(this.date(statvalue.PROPERTY_VALUES.DATE));
      });
      return labels;
    },
    filterModified() {
      return this.$store.getters.getStats().filterModified;
    },
    statvalues() {
      return this.$store.getters.getStats().statvalues.filter(x => x.PROPERTY_VALUES.STAT_ID == this.stat.ID);
    },
    statvaluesFiltered() {
      var values = [];
      var isWeekend = false;
      this.statvaluesWithBlank.forEach(statvalue => {
        isWeekend = false;
        this.weekends.forEach((weekend) => {
          if (moment(statvalue.PROPERTY_VALUES.DATE.toString()).day() == weekend) {
            isWeekend = true;
          }
        });
        if (!(this.stat.PROPERTY_VALUES.RANGE == 0 && !this.showWeekends && isWeekend)) {
          values.push(statvalue);
        }
      })
      return values;
    },
    pointColors() {
      var colors = [];
      var color = '#bbed21';
      var prevStatValue = Number.parseFloat(this.statvaluesFiltered[0]);
      var currStatValue = null;
      this.statvaluesFiltered.forEach(statvalue => {
        if (statvalue.PROPERTY_VALUES.VALUE == null) {
          colors.push(null);
        } else {
          color = '#bbed21';
          currStatValue = Number.parseFloat(statvalue.PROPERTY_VALUES.VALUE);
          if (prevStatValue == null)
            color = '#bbed21';
          else if ((prevStatValue > currStatValue && this.stat.PROPERTY_VALUES.TYPE == 0) || 
              (prevStatValue < currStatValue && this.stat.PROPERTY_VALUES.TYPE == 1))
            color = '#f32b63';
          colors.push(color);
        }
        prevStatValue = currStatValue;
      });
      return colors;
    },
    datasets() {
      var datasets = [];
      datasets.push({
        data: this.statvaluesFiltered.map(function(statvalue) {return statvalue.PROPERTY_VALUES.VALUE}),
        fill: false,
        hitRadius: 20,
        pointBackgroundColor: this.pointColors,
        pointBorderColor: this.pointColors,
        showLine: false,
      });
      var data = [];
      var color = '#bbed21';
      var left = null;
      var right = null;
      for (var i = 1; i < this.statvaluesFiltered.length; i++) {
        left = Number.parseFloat(this.statvaluesFiltered[i - 1].PROPERTY_VALUES.VALUE);
        right = Number.parseFloat(this.statvaluesFiltered[i].PROPERTY_VALUES.VALUE);
        if (left == null || right == null) {
          continue;
        } else {
          color = '#bbed21';
          if ((left > right && this.stat.PROPERTY_VALUES.TYPE == 0) || 
              (left < right && this.stat.PROPERTY_VALUES.TYPE == 1))
            color = '#f32b63';
          data = this.statvaluesFiltered.map(function() {return null});
          data[i - 1] = left;
          data[i] = right;
          datasets.push({
            data: data,
            fill: false,
            pointRadius: 0,
            borderColor: color,
          });
        }
      }
      var maxValue = Math.max.apply(Math, Array.from(this.statvaluesFiltered, statvalue => statvalue.PROPERTY_VALUES.VALUE).filter(statvalue => statvalue != null));
      var minValue = Math.min.apply(Math, Array.from(this.statvaluesFiltered, statvalue => statvalue.PROPERTY_VALUES.VALUE).filter(statvalue => statvalue != null));
      if (Math.abs(maxValue) == Infinity && Math.abs(minValue) == Infinity)
        maxValue = 1;
      if (Math.abs(maxValue) != Infinity && maxValue > 0)
        datasets.push({
          data: this.statvaluesFiltered.map(function(statvalue) { return statvalue.PROPERTY_VALUES.VALUE == null && statvalue.PROPERTY_VALUES.DATE <= moment().format('YYYYMMDD') ? maxValue : null }),
          backgroundColor: 'rgba(255, 234, 240, 0.5)',
          borderColor: 'rgba(255, 234, 240, 0.5)',
          barPercentage: '0.99',
          categoryPercentage: '0.99',
          type: 'bar',
        });
      if (Math.abs(minValue) != Infinity && minValue < 0)
        datasets.push({
          data: this.statvaluesFiltered.map(function(statvalue) { return statvalue.PROPERTY_VALUES.VALUE == null && statvalue.PROPERTY_VALUES.DATE <= moment().format('YYYYMMDD') ? minValue : null }),
          backgroundColor: 'rgba(255, 234, 240, 0.5)',
          borderColor: 'rgba(255, 234, 240, 0.5)',
          barPercentage: '0.99',
          categoryPercentage: '0.99',
          type: 'bar',
        });
      return datasets;
    },
    data() {
      return {
        labels: this.labels,
        datasets: this.datasets,
      };
    },
    options() {
      return {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            position: this.stat.PROPERTY_VALUES.TYPE == 1 ? 'top' : 'bottom',
            stacked: true,
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              reverse: this.stat.PROPERTY_VALUES.TYPE == 1,
            },
          }],
        },
        tooltips: {
          intersect: false,
          filter: function (tooltipItem) {
            return tooltipItem.datasetIndex == 0;
          },
        },
        onClick: this.onClick,
      };
    },
  },
  methods: {
    strType(type) {
      switch(type) {
        case'0':
          return 'прямая';
        case '1':
          return 'обратная';
      }
    },
    strRange(range) {
      switch(range) {
        case '0':
          return 'ежедневная';
        case '1':
          return 'еженедельная';
      }
    },
    date(date) {
      return `${date.toString().substring(6,8)}.${date.toString().substring(4,6)}`;
    },
    onClick(point, event) {
      var index = -1;
      if (event.length > 0) {
        index = event[0]._index;
      } else {
        var colWidth = (this.width - 50) / (this.statvaluesFiltered.length - 1);
        var leftOffset = 30 + colWidth / 2;
        var rect = point.target.getBoundingClientRect();
        var x = point.clientX - rect.left;
        for (var i = 0; i < this.statvaluesFiltered.length; i++) {
          if (x < leftOffset + colWidth * i) {
            index = i;
            break;
          }
        }
      }
      if (index >= 0) {
        this.$refs.showstatvalue.click();
        this.$emit('statValueSelected', this.statvaluesFiltered[index], this.stat);
      }
    },
    updateData() {
      var from = this.from;
      if (!this.filterModified && this.stat.PROPERTY_VALUES.RANGE == 1) {
        from = moment(this.to).subtract(9, 'w');
        while (from.day() != this.weekStart)
          from.add(1, 'd');
      }
      from = moment(from);
      var to = moment(this.to);

      var statvalues = this.statvalues.filter(x => x.PROPERTY_VALUES.DATE >= from.format('YYYYMMDD') && x.PROPERTY_VALUES.DATE <= to.format('YYYYMMDD'));

      var intervals = [];
      var diff = to.diff(from, this.stat.PROPERTY_VALUES.RANGE == 0 ? 'd' : 'w');
      for (var i = 0; i <= diff; i++) {
        intervals.push(from.format('YYYYMMDD'));
        from.add(1, this.stat.PROPERTY_VALUES.RANGE == 0 ? 'd' : 'w');
      }

      var values = [];
      intervals.forEach(date => {
        var added = false;
        statvalues.forEach(statvalue => {
          if (!added && statvalue.PROPERTY_VALUES.DATE.toString() == date) {
            values.push(statvalue);
            added = true;
          }
        })
        if (!added) {
          values.push({
            ID: null,
            PROPERTY_VALUES: {
              STAT_ID: this.stat.ID,
              DATE: Number.parseInt(date),
              VALUE: null,
            }
          });
        }
      });

      this.statvaluesWithBlank = values;
    },
  },
  mounted() {
    this.updateData();
  },
  components: {
    Chart,
    User,
  },
  mixins: [fitSize],
}
</script>
