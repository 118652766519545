<template>
  <!-- create modal -->
  <div class="modal fade" id="createStatModal" tabindex="-1" role="dialog" aria-labelledby="createStatModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createStatModalLabel">Добавить статистику</h5>
          <button type="button" class="close" ref="statcreateclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <label for="name">Название</label>
              <input type="text" class="form-control shadow-none" id="name" placeholder="Название статистики" v-model="name">
            </div>
            <div class="form-group">
              <label for="type">Тип</label>
              <select class="form-control shadow-none" id="type" v-model="type">
                <option value="0">Прямая</option>
                <option value="1">Обратная</option>
              </select>
            </div>
            <div class="form-group">
              <label for="range">Диапазон</label>
              <select class="form-control shadow-none" id="range" v-model="range">
                <option value="0">День</option>
                <option value="1">Неделя</option>
              </select>
            </div>
            <div class="form-group">
              <label for="post">Должность</label>
              <select class="form-control shadow-none" id="post" v-model="post">
                <option value="" selected></option>
                <option v-for="p in posts" :key="p.ID" :value="p.ID">{{ p.PROPERTY_VALUES.NAME }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="user">Сотрудник</label><br>
              <div class="row" v-if="user.id">
                <div class="col-12">
                  <User class="mb-1 d-inline-block" :user="user.id"/>
                  <button type="button" class="btn btn-danger align-top ml-2 shadow-none mt-1" aria-label="Close" @click="updateUser({})">X</button>
                </div>
              </div>
              <button type="button" class="btn btn-info shadow-none mt-1" @click="selectUser">Назначить</button>
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="submit" class="btn btn-success shadow-none float-right" @click.prevent="create">Добавить</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as STAT } from '../models/stat.js';
import User from '../components/User';

export default {
  data() {
    return {
      user: {},
      name: '',
      type: 0,
      range: 0,
      post: '',
      error: '',
    }
  },
  computed: {
    posts() {
      return this.$store.getters.getPosts();
    },
  },
  methods: {
    selectUser() {
      window.BX24.selectUser(this.updateUser);
    },
    updateUser(user) {
      this.user = user;
    },
    create() {
      this.error = '';
      if (this.name.trim().length > 0) {
        var props = {
          USER_ID: this.user ? this.user.id : '',
          POST_ID: this.post,
          NAME: this.name,
          TYPE: this.type,
          RANGE: this.range,
        }
        STAT.create(props).then((result) => {
          STAT.get({ID: result.answer.result}).then(stat => {
            this.$emit('statCreated', stat[0]);
            this.closeModal();
          })
        });
      } else {
        this.error = 'введите название статистики';
      }
    },
    closeModal() {
      this.$refs.statcreateclose.click();
      this.user = {};
      this.name = '';
      this.type = 0;
      this.range = 0;
      this.post = '';
    },
  },
  components: {
    User,
  },
}
</script>
