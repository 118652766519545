import Vue from 'vue';
import Vuex from 'vuex';
import { setUserOptions, getUserOptions } from '../models/api';
var moment = require('moment');

Vue.use(Vuex);

function calculateChildrens(department, departments, users) {
  department.childrens = [];
  department.users = users.filter(x => x.UF_DEPARTMENT.includes(Number.parseInt(department.ID))).map(x => x.ID);
  departments.forEach(d => {
    if (d.PARENT == department.ID) {
      d.childrens = calculateChildrens(d, departments, users);
      d.departments = d.childrens.map(x => x.ID);
      department.users = department.users.concat(d.users);
      department.childrens.push(d);
    }
  });
  return department.childrens;
}

export default new Vuex.Store({
  state: {
    BX24Domain: {
      member_id: '',
      domain: '',
    },
    BX24User: {},
    BX24Users: [],
    userOptions: {
      dashboards: [{
        name: 'по-умолчанию',
        statwidgets: [],
        autostatwidgets: [],
        statsWidth: 400,
        statsHeight: 200,
        showWeekends: false,
      }],
      stats: {
        statsWidth: 400,
        statsHeight: 200,
        showWeekends: false,
      },
    },
    appOptions: {
      weekStart: 1,
      weekends: [],
      isNotify: false,
      notificationTime: '00:00',
      watch: {
        users: [],
        moderators: [],
        call_duration: 60,
        phones_exclude: [],
      },
      battleplans: {
        users: [],
      },
      dealinstructions: {
        on: false,
      },
    },
    stats: {
      stats: [],
      statvalues: [],
      loaded: false,
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      filterModified: false,
    },
    dashboard: {
      calls: [],
      companies: [],
      deals: [],
      notes: [],
      loaded: false,
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      filterModified: false,
      dashboardCurrent: 0,
    },
    watch: {
      calls_real: [],
      calls_fake: [],
      calls_future: [],
      quotes: [],
      loaded: false,
      date: moment().format('YYYY-MM-DD'),
      call_type: 0,
      company_type: 0,
      call_duration_local: 60,
    },
    battleplans: {
      plans: [],
      loaded: false,
      date: moment().format('YYYY-MM-DD'),
    },
    dealinstructions: {
      categories: [],
      instructions: [],
      loaded: false,
    },
    posts: [],
    departments: [],
    tablesCreated: [],
  },
  getters: {
    getBX24Domain: state => () => {
      return state.BX24Domain;
    },
    getBX24User: state => () => {
      return state.BX24User;
    },
    getBX24Users: state => () => {
      return state.BX24Users;
    },
    getModerators: state => () => {
      return state.appOptions.watch.moderators;
    },
    getAllowedUsers: state => () => {
      var users = [state.BX24User.ID];
      state.departments.forEach(d => {
        if (d.UF_HEAD == state.BX24User.ID)
          users = users.concat(d.users);
      });
      return users;
    },
    getUserOptions: state => () => {
      return state.userOptions;
    },
    getOptions: state => () => {
      return state.appOptions;
    },
    getStats: state => () => {
      return state.stats;
    },
    getPosts: state => () => {
      return state.posts;
    },
    getDashboard: state => () => {
      return state.dashboard;
    },
    getDashboardCurrent: state => () => {
      return state.userOptions.dashboards[state.dashboard.dashboardCurrent];
    },
    getTablesCreated: state => () => {
      return state.tablesCreated;
    },
    getWatch: state => () => {
      return state.watch;
    },
    getBattlePlans: state => () => {
      return state.battleplans;
    },
    getDealInstructions: state => () => {
      return state.dealinstructions;
    },
    getDepartments: state => () => {
      return state.departments;
    },
  },
  mutations: {
    initialiseStore(state) {
      var appOptions = window.BX24.appOption.get('appOptions') != undefined ? JSON.parse(window.BX24.appOption.get('appOptions')) : undefined;
      if (appOptions != undefined) {
        state.appOptions.weekStart = appOptions.weekStart || state.appOptions.weekStart;
        state.appOptions.weekends = appOptions.weekends || state.appOptions.weekends;
        state.appOptions.isNotify = appOptions.isNotify || state.appOptions.isNotify;
        state.appOptions.notificationTime = appOptions.notificationTime || state.appOptions.notificationTime;
        state.appOptions.watch.users = appOptions.watch.users || state.appOptions.watch.users;
        state.appOptions.watch.moderators = appOptions.watch.moderators || state.appOptions.watch.moderators;
        state.appOptions.watch.call_duration = appOptions.watch.call_duration || state.appOptions.watch.call_duration;
        state.appOptions.watch.phones_exclude = appOptions.watch.phones_exclude || state.appOptions.watch.phones_exclude;
        if (appOptions.battleplans)
          state.appOptions.battleplans.users = appOptions.battleplans.users || state.appOptions.battleplans.users;
        if (appOptions.dealinstructions)
          state.appOptions.dealinstructions.on = appOptions.dealinstructions.on || state.appOptions.dealinstructions.on;
      } else {
        appOptions = state.appOptions;
      }
      getUserOptions().then(result => {
        var userOptions = JSON.parse(result.answer.result.userOptions);
        if (userOptions.dashboard) {
          userOptions.dashboard.name = 'по-умолчанию';
          userOptions.dashboards = [userOptions.dashboard];
          delete userOptions.dashboard;
        }
        state.userOptions = userOptions;
      }).catch(() => {
        state.userOptions = {
          dashboards: [{
            name: 'по-умолчанию',
            statwidgets: [],
            autostatwidgets: [],
            statsWidth: 400,
            statsHeight: 200,
            showWeekends: false,
          }],
          stats: {
            statsWidth: 400,
            statsHeight: 200,
            showWeekends: false,
          },
        };
      });
    },
    setBX24Domain(state, data) {
      state.BX24Domain = data;
    },
    setBX24User(state, data) {
      state.BX24User = data;
    },
    setBX24Users(state, data) {
      state.BX24Users = data;
    },
    setStatsWidth(state, data) {
      state.userOptions.stats.statsWidth = Number.parseInt(data);
    },
    setStatsHeight(state, data) {
      state.userOptions.stats.statsHeight = Number.parseInt(data);
    },
    setShowWeekends(state, data) {
      state.userOptions.stats.showWeekends = data;
    },
    setDashboardStatsWidth(state, data) {
      state.userOptions.dashboards[state.dashboard.dashboardCurrent].statsWidth = Number.parseInt(data);
    },
    setDashboardStatsHeight(state, data) {
      state.userOptions.dashboards[state.dashboard.dashboardCurrent].statsHeight = Number.parseInt(data);
    },
    setDashboardShowWeekends(state, data) {
      state.userOptions.dashboards[state.dashboard.dashboardCurrent].showWeekends = data;
    },
    setDashboardCurrent(state, data) {
      state.dashboard.dashboardCurrent = data;
    },
    setDashboardCurrentName(state, data) {
      state.userOptions.dashboards[state.dashboard.dashboardCurrent].name = data;
    },
    addDashboard(state, data) {
      state.userOptions.dashboards.push({
        name: data,
        statwidgets: [],
        autostatwidgets: [],
        statsWidth: 400,
        statsHeight: 200,
        showWeekends: false,
      });
    },
    setUserDashboardOptions(state, data) {
      state.userOptions.dashboards = data.dashboards;
      setUserOptions(JSON.stringify(state.userOptions));
    },
    setUserStatsOptions(state, data) {
      state.userOptions.stats = data.stats;
      setUserOptions(JSON.stringify(state.userOptions));
    },
    setOptions(state, data) {
      state.appOptions = data;
      window.BX24.appOption.set('appOptions', JSON.stringify(data));
    },
    setStats(state, data) {
      state.stats = data;
    },
    setStatStats(state, data) {
      state.stats.stats = data;
    },
    setStatValues(state, data) {
      state.statvalues = data;
    },
    setPosts(state, data) {
      state.posts = data;
    },
    setDepartments(state, data) {
      data.forEach(department => {
        data.childrens = calculateChildrens(department, data, state.BX24Users);
      })
      state.departments = data;
    },
    setDashboard(state, data) {
      state.dashboard = data;
    },
    setTablesCreated(state, data) {
      state.tablesCreated = data;
    },
    setWatch(state, data) {
      state.watch = data;
    },
    setBattlePlans(state, data) {
      state.battleplans = data;
    },
    setDealInstructions(state, data) {
      state.dealinstructions = data;
    },
  },
  actions: {
    initialiseStore(context) {
      context.commit('initialiseStore');
    },
    setBX24Domain(context, data) {
      context.commit('setBX24Domain', data);
    },
    setBX24User(context, data) {
      context.commit('setBX24User', data);
    },
    setBX24Users(context, data) {
      context.commit('setBX24Users', data);
    },
    setStatsWidth(context, data) {
      context.commit('setStatsWidth', data);
    },
    setStatsHeight(context, data) {
      context.commit('setStatsHeight', data);
    },
    setShowWeekends(context, data) {
      context.commit('setShowWeekends', data);
    },
    setDashboardStatsWidth(context, data) {
      context.commit('setDashboardStatsWidth', data);
    },
    setDashboardStatsHeight(context, data) {
      context.commit('setDashboardStatsHeight', data);
    },
    setDashboardShowWeekends(context, data) {
      context.commit('setDashboardShowWeekends', data);
    },
    setUserDashboardOptions(context, data) {
      context.commit('setUserDashboardOptions', data);
    },
    setUserStatsOptions(context, data) {
      context.commit('setUserStatsOptions', data);
    },
    setOptions(context, data) {
      context.commit('setOptions', data);
    },
    setStats(context, data) {
      context.commit('setStats', data);
    },
    setStatStats(context, data) {
      context.commit('setStatStats', data);
    },
    setStatValues(context, data) {
      context.commit('setStatValues', data);
    },
    setPosts(context, data) {
      context.commit('setPosts', data);
    },
    setDepartments(context, data) {
      context.commit('setDepartments', data);
    },
    setDashboard(context, data) {
      context.commit('setDashboard', data);
    },
    setDashboardCurrent(context, data) {
      context.commit('setDashboardCurrent', data);
    },
    setTablesCreated(context, data) {
      context.commit('setTablesCreated', data);
    },
    setWatch(context, data) {
      context.commit('setWatch', data);
    },
    setBattlePlans(context, data) {
      context.commit('setBattlePlans', data);
    },
    setDealInstructions(context, data) {
      context.commit('setDealInstructions', data);
    },
  },
});
