<template>
  <!-- add dashboard modal -->
  <div class="modal fade" id="dashboardModalAdd" tabindex="-1" role="dialog" aria-labelledby="dashboardModalAdd" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="dashboardModalAdd">Добавить дашборд</h5>
          <button type="button" class="close" ref="dashboardaddclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <input type="text" class="form-control shadow-none" id="value" placeholder="Название" v-model="value">
            </div>
            <div v-show="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div class="form-group">
              <button type="button" class="btn btn-success shadow-none float-right" @click.prevent="save">Создать</button>
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Отменить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: '',
      value: '',
    }
  },
  methods: {
    save() {
      this.error = '';
      if (this.value.trim().length > 0) {
        this.$store.commit('addDashboard', this.value);
        this.$emit('updateDashboard');
        this.closeModal();
        this.value = '';
      } else {
        this.error = 'Введите название';
      }
    },
    closeModal() {
      this.$refs.dashboardaddclose.click();
    },
  },
}
</script>
