<template>
  <div>
    <h2 class="pagetitle">
      <span class="mr-3">Боевые планы</span>
      <button type="button" class="btn btn-info shadow-none mb-1" @click.prevent="filterModified = true; updateData()" :disabled="updating" title="Обновить данные">
        <i v-if="!updating" class="fa fa-refresh"></i>
        <i v-if="updating" class="fa fa-refresh fa-spin"></i>
      </button>

      <div class="d-inline-block align-middle ml-2 mb-1">
        <div class="form-inline">
          <input type="date" class="form-control shadow-none mr-2" id="date" v-model="date" required>
        </div>
      </div>

      <button v-if="$store.getters.getBX24User().ADMIN" type="button" class="btn btn-outline-secondary shadow-none float-right" data-toggle="button" :aria-pressed="showSettings" @click="showSettings = !showSettings" title="Показать настройки">
        <i class="fa fa-cog"></i>
      </button>
    </h2>

    <form class="mb-2" v-show="showSettings && $store.getters.getBX24User().ADMIN">
      <label for="user">Сотрудники</label><br>
      <div class="row">
        <div class="col-sm-6 col-md-4" v-for="user in options.battleplans.users" :key="user">
          <User class="mb-1 d-inline-block" :user="user"/>
          <button type="button" class="btn btn-danger align-top ml-2 shadow-none btn-close" aria-label="Close" @click="removeUser(user)">X</button>
        </div>
      </div>
      <button type="button" class="btn btn-info shadow-none mt-1" @click="selectUsers">Выбрать</button>
      <hr>
    </form>

    <div class="row mx-0 my-3 row-header">
      <div class="col"><b>Сотрудник</b></div>
      <div class="col"><b>Часы</b></div>
      <div class="col"><b>Утверждено</b></div>
      <div class="col"><b>Выполнено</b></div>
    </div>

    <div class="accordion" id="accordion">
      <div class="card" v-for="user in data" :key="user.user">
        <div class="card-header" :id="'heading_' + user.user" type="button" data-toggle="collapse" :data-target="'#collapse_' + user.user" aria-expanded="false" :aria-controls="'collapse_' + user.user">
          <div class="row">
            <div class="col"><User :user="user.user"/></div>
            <div class="col justify-content-center align-self-center"><b>{{ hoursStat(user) }}</b></div>
            <div class="col justify-content-center align-self-center"><b>{{ planApprovedStat(user) }}</b></div>
            <div class="col justify-content-center align-self-center"><b>{{ planCompletedStat(user) }}</b></div>
          </div>
        </div>
        <div :id="'collapse_' + user.user" class="collapse" :aria-labelledby="'heading_' + user.user" data-parent="#accordion">
          <div class="card-body pt-0 pb-0">
            <div class="row mt-3">
              <div class="col"><b>План</b></div>
              <div class="col"><b>Часы</b></div>
              <div class="col"></div>
            </div>
            <div class="row border-bottom">
              <div class="form-group form-inline col">
                <input type="text" class="form-control mr-2" v-model="user.new.NAME">
              </div>
              <div class="form-group form-inline col">
                <input type="number" class="form-control mr-2" v-model="user.new.HOURS">
              </div>
              <div class="form-group form-inline col">
                <button class="form-control mr-2 btn btn-info shadow-none" @click="addRow(user)">Добавить</button>
              </div>
            </div>

            <div class="row mt-2" v-if="user.rows.length > 0">
              <div class="col"><b>План</b></div>
              <div class="col"><b>Часы</b></div>
              <div class="col"><b>Утверждено</b></div>
              <div class="col"><b>Выполнено</b></div>
              <div class="col"></div>
            </div>
            <div class="row border-bottom pb-1 pt-1" v-for="(row, index) in user.rows" :key="'row_' + index">
              <div class="col">
                <span v-if="row.SORT > 0">{{ row.PROPERTY_VALUES.NAME }}</span>
                <input v-if="row.SORT < 0" type="text" class="form-control mr-2" v-model="row.PROPERTY_VALUES.NAME">
              </div>
              <div class="col">
                <span v-if="row.SORT > 0">{{ row.PROPERTY_VALUES.HOURS }}</span>
                <input v-if="row.SORT < 0" type="number" class="form-control mr-2" v-model="row.PROPERTY_VALUES.HOURS">
              </div>
              <div class="col">
                <label :for="'aprvd_' + row.ID">{{ userName(row.PROPERTY_VALUES.APPROVED_BY) }}<br>{{ time(row.PROPERTY_VALUES.APPROVED_TIME) }}</label>
                <input type="checkbox" :disabled="!canApprove" class="ml-2 shadow-none" v-model="row.PROPERTY_VALUES.APPROVED" true-value="true" false-value="false" @change="approve(row)">
              </div>
              <div class="col">
                <label :for="'cmpld_' + row.ID">{{ userName(row.PROPERTY_VALUES.COMPLETED_BY) }}<br>{{ time(row.PROPERTY_VALUES.COMPLETED_TIME) }}</label>
                <input type="checkbox" class="ml-2 shadow-none" v-model="row.PROPERTY_VALUES.COMPLETED" true-value="true" false-value="false" @change="complete(row)">
              </div>
              <div class="col">
                <button v-if="row.SORT > 0" class="btn btn-outline-secondary shadow-none" @click="row.SORT = -row.SORT">Редактировать</button>
                <button v-if="row.SORT < 0" class="btn btn-outline-info shadow-none" @click="updatePlan(row); row.SORT = -row.SORT">Сохранить</button>
                <button v-if="row.SORT < 0" class="btn btn-danger ml-2 shadow-none" @click="deletePlan(row)">X</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { } from '../models/api';
import { fitSize } from '../mixins/fitSize';
import User from '../components/User';
import { default as BATTLEPLAN } from '../models/battleplan';
var moment = require('moment');

export default {
  data() {
    return {
      date: this.$store.getters.getBattlePlans().date,
      plans: this.$store.getters.getBattlePlans().plans,
      updating: false,
      showSettings: false,
      moment: moment,
    }
  },
  computed: {
    BX24Domain() {
      return this.$store.getters.getBX24Domain();
    },
    battleplans() {
      return this.$store.getters.getBattlePlans();
    },
    options() {
      return this.$store.getters.getOptions();
    },
    user() {
      return this.$store.getters.getBX24User();
    },
    users() {
      return this.$store.getters.getBX24Users();
    },
    data() {
      var data = [];
      var users = [this.$store.getters.getAllowedUsers()];
      if (this.$store.getters.getBX24User().ADMIN)
        users = this.options.battleplans.users;
      users.forEach(user => {
        data.push({
          user: user,
          rows: this.plans.filter(x => x.PROPERTY_VALUES.USER_ID == user),
          new: {
            NAME: '',
            HOURS: '',
          },
        });
      });
      return data;
    },
    canApprove() {
      if (this.user.ADMIN == true)
        return true;
      else
        return false;
    },
  },
  methods: {
    updateData() {
      this.updating = true;
      var filter = {
        PROPERTY_USER_ID: this.user.ID,
        '>=PROPERTY_DATE': moment(this.date).format('YYYYMMDD'),
        '<=PROPERTY_DATE': moment(this.date).format('YYYYMMDD'),
      };
      if (this.user.ADMIN) filter.PROPERTY_USER_ID = []; 
      BATTLEPLAN.get(filter).then(plans => {
        this.updating = false;
        this.plans = plans;
        this.$store.dispatch('setBattlePlans', {
          plans: plans,
          loaded: true,
          date: this.date,
        });
      });
    },
    updateBattlePlans() {
      this.$store.dispatch('setBattlePlans', {
        plans: this.battleplans.plans,
        loaded: true,
        date: this.date,
      });
    },
    selectUsers() {
      window.BX24.selectUsers(this.updateUsers);
    },
    updateUsers(selectedUsers) {
      var options = this.options;
      options.battleplans.users = selectedUsers.map(x => x.id);
      this.$store.dispatch('setOptions', options);
    },
    removeUser(userId) {
      var options = this.options;
      options.battleplans.users.splice(options.battleplans.users.indexOf(userId), 1);
      this.$store.dispatch('setOptions', options);
    },
    userName(userId) {
      var u = undefined;
      this.users.forEach(user => {
        if (userId == user.ID)
          u = user;
      });
      if (u)
        return `${u.LAST_NAME} ${u.NAME}`;
      else
        return '';
    },
    addRow(user) {
      if (user.new.NAME.trim() != '' && Number.isInteger(Number.parseInt(user.new.HOURS))) {
        var props = {
          DATE: moment(this.date).format('YYYYMMDD'),
          USER_ID: user.user,
          NAME: user.new.NAME,
          HOURS: user.new.HOURS,
          APPROVED: false,
          COMPLETED: false,
        };
        BATTLEPLAN.create(props).then((result) => {
          BATTLEPLAN.get({ID: result.answer.result}).then(plan => {
            user.new.NAME = '';
            user.new.HOURS = '';
            this.plans.push(plan[0]);
            this.$store.dispatch('setBattlePlans', {
              plans: this.plans,
              loaded: true,
              date: this.date,
            });
          })
        });
      }
    },
    approve(plan) {
      if (plan.PROPERTY_VALUES.APPROVED == 'true') {
        plan.PROPERTY_VALUES.APPROVED_TIME = moment().format('YYYYMMDD HH:mm');
        plan.PROPERTY_VALUES.APPROVED_BY = this.user.ID;
      } else {
        plan.PROPERTY_VALUES.APPROVED_TIME = '';
        plan.PROPERTY_VALUES.APPROVED_BY = '';
      }
      this.updatePlan(plan);
    },
    complete(plan) {
      if (plan.PROPERTY_VALUES.COMPLETED == 'true') {
        plan.PROPERTY_VALUES.COMPLETED_TIME = moment().format('YYYYMMDD HH:mm');
        plan.PROPERTY_VALUES.COMPLETED_BY = this.user.ID;
      } else {
        plan.PROPERTY_VALUES.COMPLETED_TIME = '';
        plan.PROPERTY_VALUES.COMPLETED_BY = '';
      }
      this.updatePlan(plan);
    },
    updatePlan(plan) {
      BATTLEPLAN.update(plan.ID, plan.PROPERTY_VALUES).then(() => {
        this.$store.dispatch('setBattlePlans', {
          plans: this.plans,
          loaded: true,
          date: this.date,
        });
      });
    },
    deletePlan(plan) {
      BATTLEPLAN.del(plan.ID).then(() => {
        this.plans.splice(this.plans.indexOf(plan), 1);
        this.$store.dispatch('setBattlePlans', {
          plans: this.plans,
          loaded: true,
          date: this.date,
        });
      });
    },
    planApprovedStat(user) {
      return `${user.rows.filter(x => x.PROPERTY_VALUES.APPROVED == 'true').length}/${user.rows.length}`;
    },
    planCompletedStat(user) {
      return `${user.rows.filter(x => x.PROPERTY_VALUES.COMPLETED == 'true').length}/${user.rows.length}`;
    },
    hoursStat(user) {
      var all = 0;
      var completed = 0;
      user.rows.forEach(plan => {
        all += Number.parseInt(plan.PROPERTY_VALUES.HOURS);
        if (plan.PROPERTY_VALUES.COMPLETED == 'true')
          completed += Number.parseInt(plan.PROPERTY_VALUES.HOURS);
      });
      return `${completed}/${all}`;
    },
    time(time) {
      if (time)
        return moment(time, 'YYYYMMDD HH:ss').format('MM.DD.YYYY HH:ss');
      else
        return '';
    },
  },
  mounted() {
    if (!this.battleplans.loaded) {
      this.updateData();
    }
  },
  components: {
    User,
  },
  mixins: [fitSize],
}
</script>

<style>
.border-bottom {
  border-bottom: 1px solid lightgrey;
}
.btn-close {
  width: 48px;
  height: 48px;
}
</style>
