<template>
  <div>
    <h2 class="pagetitle">
      <span class="mr-3">Должности</span>
      <button v-if="user.ADMIN" type="button" class="btn btn-info shadow-none float-right ml-2" data-toggle="modal" data-target="#createPostModal" title="Добавить шаблон должности">
        <i class="fa fa-plus"></i> Добавить должность
      </button>
    </h2>

    <div class="card-columns m-0">
      <Post v-for="post in posts" :key="post.ID" :post="post" @postSelected="postSelected" @statTemplateSelected="statTemplateSelected"/>
    </div>

    <PostModalCreate @postCreated="postCreated"/>
    <PostModalEdit :postActive="postActive" @postSaved="postSaved" @postDeleted="postDeleted"/>
    <StatTemplateModalCreate :postActive="postActive" @statTemplateCreated="statTemplateCreated"/>
    <StatTemplateModalEdit :postActive="postActive" :statTemplateActive="statTemplateActive" @statTemplateSaved="statTemplateSaved" @statTemplateDeleted="statTemplateDeleted"/>
  </div>
</template>

<script>
import { default as POST } from '../models/post';
import { default as STATTEMPLATE } from '../models/stattemplate';
import { default as STAT } from '../models/stat';
import Post from '../components/Post';
import PostModalCreate from '../components/PostModalCreate';
import PostModalEdit from '../components/PostModalEdit';
import StatTemplateModalCreate from '../components/StatTemplateModalCreate';
import StatTemplateModalEdit from '../components/StatTemplateModalEdit';
import deepcopy from '../helpers/deepcopy';
import { fitSize } from '../mixins/fitSize';

export default {
  data() {
    return {
      post: {
        PROPERTY_VALUES: {},
      },
      postActive: {
        PROPERTY_VALUES: {},
      },
      statTemplate: {
        PROPERTY_VALUES: {},
      },
      statTemplateActive: {
        PROPERTY_VALUES: {},
      },
    }
  },
  computed: {
    user() {
      return this.$store.getters.getBX24User();
    },
    posts() {
      return this.$store.getters.getPosts();
    },
    stats() {
      return this.$store.getters.getStats().stats;
    },
  },
  methods: {
    postCreated(post) {
      post.statTemplates = [];
      this.posts.push(post);
    },
    postSelected(post) {
      this.post = post;
      this.postActive = deepcopy(post);
    },
    postSaved(post) {
      POST.copy(post, this.post);
    },
    postDeleted(post) {
      var index = -1;
      for (var i = 0; i < this.posts.length; i++)
        if (this.posts[i].ID === post.ID)
          index = i;
      this.posts.splice(index, 1);

      var stats = this.stats.filter(stat => stat.PROPERTY_VALUES.POST_ID == post.ID);
      STAT.del(stats.map(stat => stat.ID));
      this.$store.dispatch('setStatStats', this.stats.filter(stat => stat.PROPERTY_VALUES.POST_ID != post.ID));
    },
    statTemplateSelected(statTemplate, post) {
      this.statTemplate = statTemplate;
      this.statTemplateActive = deepcopy(statTemplate);
      this.post = post;
      this.postActive = deepcopy(post);
    },
    statTemplateCreated(statTemplate) {
      var index = -1;
      for (var i = 0; i < this.posts.length; i++)
        if (this.posts[i].ID === this.postActive.ID)
          index = i;
      this.posts[index].statTemplates.push(statTemplate);

      var stats = this.stats.filter(stat => stat.PROPERTY_VALUES.POST_ID == this.postActive.ID);
      var users = [...new Set(stats.map(stat => stat.PROPERTY_VALUES.USER_ID))];
      if (users.length > 0) {
        var props = [];
        users.forEach(user => {
          props.push({
            USER_ID: user,
            POST_ID: this.postActive.ID,
            STATTEMPLATE_ID: statTemplate.ID,
            NAME: statTemplate.PROPERTY_VALUES.NAME,
            TYPE: statTemplate.PROPERTY_VALUES.TYPE,
            RANGE: statTemplate.PROPERTY_VALUES.RANGE,
          });
        })
        STAT.create(props).then((result) => {
          STAT.get({ID: result}).then(stats => {
            stats.forEach(stat => {
              this.stats.push(stat);
            });
          })
        });
      }
    },
    statTemplateSaved(statTemplate) {
      STATTEMPLATE.copy(statTemplate, this.statTemplate);

      var stats = this.stats.filter(stat => stat.PROPERTY_VALUES.STATTEMPLATE_ID == statTemplate.ID);
      var props = {
        NAME: this.statTemplate.PROPERTY_VALUES.NAME,
        TYPE: this.statTemplate.PROPERTY_VALUES.TYPE,
        RANGE: this.statTemplate.PROPERTY_VALUES.RANGE,
      }
      stats.forEach(stat => {
        stat.PROPERTY_VALUES.NAME = this.statTemplate.PROPERTY_VALUES.NAME;
        stat.PROPERTY_VALUES.TYPE = this.statTemplate.PROPERTY_VALUES.TYPE;
        stat.PROPERTY_VALUES.RANGE = this.statTemplate.PROPERTY_VALUES.RANGE;
      });
      STAT.update(stats.map(stat => stat.ID), props);
    },
    statTemplateDeleted(statTemplate) {
      var index = -1;
      for (var i = 0; i < this.post.statTemplates.length; i++)
        if (this.post.statTemplates[i].ID === statTemplate.ID)
          index = i;
      this.post.statTemplates.splice(index, 1);

      var stats = this.stats.filter(stat => stat.PROPERTY_VALUES.STATTEMPLATE_ID == statTemplate.ID);
      STAT.del(stats.map(stat => stat.ID));
      this.$store.dispatch('setStatStats', this.stats.filter(stat => stat.PROPERTY_VALUES.STATTEMPLATE_ID != statTemplate.ID));
    },
  },
  components: {
    Post,
    PostModalCreate,
    PostModalEdit,
    StatTemplateModalEdit,
    StatTemplateModalCreate,
  },
  mixins: [fitSize],
}
</script>
