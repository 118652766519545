import { createTable, createProperties, deleteTable, getRows, createRow, updateRow, deleteRow } from './api.js';

var model = {
  NAME: 'posts',
  PROPERTIES: [
    { PROPERTY: 'NAME', NAME: 'Stat name', TYPE: 'S' },
  ],
}

function copy(from, to) {
  to.PROPERTY_VALUES.NAME = from.PROPERTY_VALUES.NAME;
}

function prepare() {
  return new Promise((resolve, reject) => {
    createTable(model).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function addProps(props) {
  return new Promise((resolve, reject) => {
    createProperties(model, props).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function clear() {
  return new Promise((resolve, reject) => {
    deleteTable(model).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function get(filter = {}) {
  return new Promise((resolve, reject) => {
    var data = {
      ENTITY: model.NAME,
      SORT: {},
      FILTER: filter,
    };
    getRows(data).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function create(props) {
  return new Promise((resolve, reject) => {
    var data = {
      ENTITY: model.NAME,
      NAME: model.NAME,
      PROPERTIES: props,
    };
    createRow(data).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function update(id, props) {
  return new Promise((resolve, reject) => {
    var data = {
      ENTITY: model.NAME,
      ID: id,
      PROPERTIES: props,
    };
    updateRow(data).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

function del(id) {
  return new Promise((resolve, reject) => {
    var data = {
      ENTITY: model.NAME,
      ID: id,
    };
    deleteRow(data).then(result => resolve(result)).catch(reason => reject(reason));
  });
}

export default {
  model,
  copy,
  prepare,
  addProps,
  clear,
  get,
  create,
  update,
  del,
}
