<template>
  <!-- stat info modal -->
  <div class="modal fade" id="dateStatInfoModal" tabindex="-1" role="dialog" aria-labelledby="dateStatInfoModalLabel" aria-hidden="true">
    <div class="modal-dialog mw-100 w-75" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="dateStatInfoModalLabel">{{ statWidgetActive.NAME }} - {{ day }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table m-0">
            <thead class="thead row-header">
              <th scope="col">Сотрудник</th>
              <th scope="col">Значение</th>
              <th scope="col">Информация</th>
            </thead>
            <tbody>
              <tr v-for="dataset in dateInfo" :key="dataset.user">
                <td scope="row">{{ dataset.user }}</td>
                <td>{{ dataset.data }}</td>
                <td>
                  <div v-for="value in dataset.values" :key="value.ID" class="row">
                    <span class="badge badge-secondary">создано {{ datetime(value.DATE_CREATE) }} {{ user(value.CREATED_BY) }}</span>
                    <span v-if="value.TIMESTAMP_X != value.DATE_CREATE" class="badge badge-warning">изменено {{ datetime(value.TIMESTAMP_X) }} {{ user(value.MODIFIED_BY) }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <form action="#">
            <div class="form-group">
              <button type="button" class="btn btn-secondary shadow-none float-right mr-2" data-dismiss="modal">Закрыть</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fitSize } from '../mixins/fitSize';
var moment = require('moment');

export default {
  props: {
    statWidgetActive: Object,
    dateInfo: Array,
  },
  computed: {
    day() {
      return this.dateInfo.length > 0 ? this.dateInfo[0].day : '';
    },
    BX24Domain() {
      return this.$store.getters.getBX24Domain();
    },
    users() {
      return this.$store.getters.getBX24Users();
    },
  },
  methods: {
    datetime(date) {
      return `${moment(date).format('DD.MM.YYYY HH:mm')}`;
    },
    user(userId) {
      var u = null;
      this.users.forEach(user => {
        if (userId == user.ID)
          u = user;
      });
      return u == null ? '' : `${u.NAME} ${u.LAST_NAME}`;
    },
  },
  mixins: [fitSize],
}
</script>

<style scoped>
.card {
  padding: 20px;
}
</style>
